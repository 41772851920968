import { useCallback, useMemo } from 'react';
import serviceAPI from '../../services/ServiceService';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { Box, FormControl, FormControlLabel, FormGroup, Stack, Switch, Skeleton, FormHelperText } from '@mui/material';
import SimpleAddServiceDialog from '../SimpleAddServiceDialog';
import { IService } from '../../models/IService';
import OverrideInput from './OverrideInput';
import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import CommissionSelect from './CommissionSelect';
import { EmployeeCommissionType } from '../../models/IEmployee';
import useAuth from '../../hooks/useAuth';

type ServiceFormRow = {
    id: number;
    price: number | null;
    has_price_override: boolean;
    duration: number | null;
    has_duration_override: boolean;
    has_commission_override: boolean;
    commission_type: EmployeeCommissionType | null;
    commission_amount: number | null;
};

interface ServiceSelectProps {
    value: ServiceFormRow[];
    touched: FormikTouched<FormikValues>;
    errors: FormikErrors<FormikValues>;
    setFieldValue: (key: string, value: any) => void;
    setFieldTouched: (a: string, value?: boolean) => void;
}

const ServiceSelect = ({ value, touched, errors, setFieldTouched, setFieldValue }: ServiceSelectProps) => {
    const { user } = useAuth();
    const comissionEnabled = useMemo(() => Boolean(user?.currentCompany.settings?.enable_commissions), [user]);
    const matchMd = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('md'));
    const matchXs = useMediaQuery('(max-width:420px)');
    const services = serviceAPI.useFetchAllServicesQuery({});
    const serviceOptions = services?.data?.data;

    const isSelected = useCallback((id) => value.some((val) => val.id === id), [value]);
    const serviceById = useCallback((id) => serviceOptions?.find((service) => service.id === id), [serviceOptions]);
    const getItemIndex = useCallback(
        (id) => {
            const ids = value.map((v) => v.id);
            return ids.indexOf(id);
        },
        [value]
    );

    const handleServiceChange = useCallback(
        (id: number) => {
            const serviceValue = value[getItemIndex(id)];
            const price = serviceById(id)?.price ?? null;
            const duration = serviceById(id)?.duration ?? null;
            const commission_type = serviceValue?.commission_type ?? null;
            const commission_amount = serviceValue?.commission_amount ?? null;
            const newValue: ServiceFormRow[] = isSelected(id)
                ? [...value].filter((v) => v.id !== id)
                : [
                      ...value,
                      {
                          id,
                          price,
                          duration,
                          has_price_override: false,
                          has_duration_override: false,
                          has_commission_override: false,
                          commission_type,
                          commission_amount
                      }
                  ];
            setFieldValue(
                'services',
                [...newValue].sort((a, b) => a.id - b.id)
            );
        },
        [serviceById, isSelected, value, setFieldValue]
    );

    const handleServicePriceChange = useCallback(
        (index: number, val: number | undefined) => {
            if (index >= 0) {
                setFieldValue(`services.${index}.price`, val ?? '');
            }
        },
        [setFieldValue]
    );

    const handleDurationPriceChange = useCallback(
        (index: number, val: number | undefined) => {
            if (index >= 0) {
                setFieldValue(`services.${index}.duration`, val ?? '');
            }
        },
        [setFieldValue]
    );

    const handlePriceOverrideToggle = useCallback(
        (val: boolean, service: IService) => {
            const index = getItemIndex(service.id);
            setFieldValue(`services.${index}.has_price_override`, val);
            setFieldValue(`services.${index}.price`, val ? service.price : null);
            setFieldTouched(`services.${index}.price`, val);
        },
        [getItemIndex, setFieldTouched, setFieldValue]
    );

    const handlDurationOverrideToggle = useCallback(
        (val: boolean, service: IService) => {
            const index = getItemIndex(service.id);
            setFieldValue(`services.${index}.has_duration_override`, val);
            setFieldValue(`services.${index}.duration`, val ? service.duration : null);
            setFieldTouched(`services.${index}.duration`, val);
        },
        [getItemIndex, setFieldTouched, setFieldValue]
    );

    const handleComissionOverrideToggle = useCallback(
        (val: boolean, serviceId: number) => {
            const index = getItemIndex(serviceId);
            setFieldValue(`services.${index}.has_commission_override`, val);
            setFieldValue(`services.${index}.commission_type`, val ? EmployeeCommissionType.Fixed : null);
            setFieldValue(`services.${index}.commission_amount`, val ? 0 : null);
            setFieldTouched(`services.${index}.commission_type`, val);
            setFieldTouched(`services.${index}.commission_amount`, val);
        },
        [getItemIndex, setFieldTouched, setFieldValue]
    );

    const handleCommissionTypeChange = useCallback(
        (val: EmployeeCommissionType | null, serviceId: number) => {
            const index = getItemIndex(serviceId);
            setFieldValue(`services.${index}.commission_type`, val);
            setFieldTouched(`services.${index}.commission_type`, true);
        },
        [getItemIndex, setFieldTouched, setFieldValue]
    );

    const handleCommissionAmountChange = useCallback(
        (val: number | null, serviceId: number) => {
            const index = getItemIndex(serviceId);
            setFieldValue(`services.${index}.commission_amount`, val);
            setFieldTouched(`services.${index}.commission_amount`, true);
        },
        [getItemIndex, setFieldTouched, setFieldValue]
    );

    const commonError = useMemo(() => (typeof errors.services === 'string' ? errors.services : null), [errors.services]);
    const rowErrors = useMemo(() => (!!errors.services && typeof errors.services !== 'string' ? errors?.services : null), [
        errors.services
    ]);

    const getRowError = useCallback(
        (id: number, field: 'duration' | 'price' | 'commission_type' | 'commission_amount') => {
            const index = getItemIndex(id);
            const isTouched = Array.isArray(touched.services) && !!touched?.services?.[index]?.[field];
            if (index >= 0 && Array.isArray(rowErrors) && isTouched) {
                const indexedError = rowErrors[index];
                if (indexedError && typeof indexedError === 'object') {
                    return indexedError[field];
                }
            }
            return null;
        },
        [getItemIndex, rowErrors, touched.services]
    );

    const getCommissionError = useCallback(
        (serviceId) => {
            const typeError = getRowError(serviceId, 'commission_type');
            const amountError = getRowError(serviceId, 'commission_amount');

            if (typeError) {
                return String(typeError);
            }

            if (amountError) {
                return String(amountError);
            }

            return undefined;
        },
        [getRowError]
    );

    const handleBlur = useCallback(
        (id: number, field) => {
            const index = getItemIndex(id);
            if (index >= 0) {
                setFieldTouched(`services.${index}.${field}`);
            }
        },
        [getItemIndex, setFieldTouched]
    );

    const serviceLabel = useCallback((service: IService) => {
        if (service.price) {
            return `${service.name} ($${service.price})`;
        }

        return service.name;
    }, []);

    const renderItemPriceInput = useCallback(
        (service: IService) => {
            const valueItem = value[getItemIndex(service.id)];

            if (valueItem) {
                return (
                    <Stack spacing={matchXs ? 2 : 1} direction={matchXs ? 'column' : 'row'} alignItems="flex-start">
                        <OverrideInput
                            hasOverride={valueItem.has_price_override}
                            overrideValue={valueItem.price}
                            onOverrideChange={(v) => handlePriceOverrideToggle(v, service)}
                            onChange={(values) => {
                                handleServicePriceChange(getItemIndex(service.id), values.floatValue);
                            }}
                            fieldProps={{
                                prefix: '$',
                                decimalScale: 2,
                                error: !!getRowError(service.id, 'price'),
                                helperText: getRowError(service.id, 'price'),
                                onBlur: () => handleBlur(service.id, 'price'),
                                label: 'Price'
                            }}
                            toggleLabel="Set Price"
                        />
                        <OverrideInput
                            hasOverride={valueItem.has_duration_override}
                            overrideValue={valueItem.duration}
                            onOverrideChange={(v) => handlDurationOverrideToggle(v, service)}
                            onChange={(values) => {
                                handleDurationPriceChange(getItemIndex(service.id), values.floatValue);
                            }}
                            fieldProps={{
                                suffix: ' min',
                                decimalScale: 0,
                                error: !!getRowError(service.id, 'duration'),
                                helperText: getRowError(service.id, 'duration'),
                                onBlur: () => handleBlur(service.id, 'duration'),
                                label: 'Duration'
                            }}
                            toggleLabel="Set Duration"
                        />
                        {comissionEnabled ? (
                            <CommissionSelect
                                hasOverride={valueItem.has_commission_override}
                                amount={valueItem.commission_amount}
                                type={valueItem.commission_type}
                                onToggle={(v: boolean) => handleComissionOverrideToggle(v, service.id)}
                                onTypeChange={(v) => handleCommissionTypeChange(v, service.id)}
                                onAmountChange={(v) => handleCommissionAmountChange(v, service.id)}
                                error={getCommissionError(service.id)}
                                onBlur={() => handleBlur(service.id, 'commission_amount')}
                            />
                        ) : null}
                    </Stack>
                );
            }

            return null;
        },
        [
            value,
            getItemIndex,
            matchXs,
            getRowError,
            getCommissionError,
            handlePriceOverrideToggle,
            handleServicePriceChange,
            handleBlur,
            handlDurationOverrideToggle,
            handleDurationPriceChange,
            handleComissionOverrideToggle,
            handleCommissionTypeChange,
            handleCommissionAmountChange
        ]
    );

    return services?.data?.data ? (
        <FormControl fullWidth error={!!touched.services && !!errors.services}>
            <FormGroup>
                {services?.data?.data.map((service) => (
                    <Box mb={2} key={service.id} sx={{ maxWidth: '100%' }}>
                        <Stack direction={matchMd ? 'column' : 'row'} sx={{ alignItems: matchMd ? 'flex-start' : 'center' }} spacing={1.5}>
                            <FormControlLabel
                                control={<Switch checked={isSelected(service.id)} onChange={() => handleServiceChange(service.id)} />}
                                label={serviceLabel(service)}
                                sx={{ flexGrow: 1 }}
                            />
                            <Box
                                sx={{
                                    flexGrow: 0,
                                    flexShrink: 0,
                                    maxWidth: '100%'
                                }}
                            >
                                {renderItemPriceInput(service)}
                            </Box>
                        </Stack>
                    </Box>
                ))}
            </FormGroup>
            {commonError && <FormHelperText error>{commonError}</FormHelperText>}
            <Box>
                <SimpleAddServiceDialog onSuccess={(service) => handleServiceChange(service.id)} />
            </Box>
        </FormControl>
    ) : (
        <Skeleton animation="wave" height={80} />
    );
};

export default ServiceSelect;
