import { ReactNode } from 'react';
import { Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { TableBody } from '@material-ui/core';
import TableLoader from './TableLoader';
import TableEmpty from './TableEmpty';

type CBTablePropsType<T> = {
    columns: Array<string>;
    rows: Array<T>;
    renderRow: (row: T) => ReactNode;
    isLoading?: boolean;
};

const CBTable = <K,>({ columns, rows, renderRow, isLoading }: CBTablePropsType<K>) => (
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell key={column}>{column}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {isLoading ? <TableLoader colSpan={columns.length} /> : null}
                {!isLoading && !rows.length ? <TableEmpty colSpan={columns.length} /> : null}
                {!!rows.length && !isLoading ? rows.map(renderRow) : null}
            </TableBody>
        </Table>
    </TableContainer>
);

export default CBTable;
