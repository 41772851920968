import { FC, useCallback, useEffect, useState } from 'react';
import { IAppointment } from '../../../models/IAppointment';
import useSaveAppointment from '../../../views/calendar/appointment-card/appointment-details/hooks/useSaveAppointment';
import useAppointmentFunctions from '../../../views/calendar/components/hooks/useAppointmentFunctions';
import { FormControlLabel, Switch, Box, Grid } from '@mui/material';
import ResendConfirmationButton from './ResendConfirmationButton';
import ResendWaiverButton from './ResendWaiverButton';
import useAuth from '../../../hooks/useAuth';

const AppointmentNotifications: FC<{ appointment: IAppointment; isReadOnly?: boolean }> = ({ appointment, isReadOnly }) => {
    const [checked, setChecked] = useState(Boolean(appointment.is_notifications_enabled));
    const { user } = useAuth();
    const { saveAppointment } = useSaveAppointment();
    const { formatAppointmentPayload } = useAppointmentFunctions();

    const isReSendBtnDisabled = !user?.currentCompany.settings?.notifications?.enabled;

    const handleToggle = useCallback(
        (e: unknown, v: boolean) => {
            setChecked(v);
            const data = formatAppointmentPayload(appointment);
            data.is_notifications_enabled = v;
            saveAppointment(data, undefined, appointment.id.toString());
        },
        [appointment, formatAppointmentPayload, saveAppointment]
    );

    useEffect(() => {
        setChecked((prev) => {
            const incomingValue = Boolean(appointment.is_notifications_enabled);
            return prev !== incomingValue ? incomingValue : prev;
        });
    }, [appointment.is_notifications_enabled]);

    return (
        <Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item sx={{ flexGrow: 1 }}>
                <Box>
                    <FormControlLabel
                        control={<Switch checked={checked} onChange={handleToggle} disabled={isReadOnly} />}
                        label="Send Notifications"
                        labelPlacement="start"
                        sx={{ ml: 0 }}
                    />
                </Box>
            </Grid>

            {!isReadOnly && (
                <Grid item xs="auto">
                    <ResendConfirmationButton appointmentId={appointment.id} disabled={isReSendBtnDisabled} />
                </Grid>
            )}

            {appointment.has_waiver === 'not_signed' && !isReadOnly && (
                <Grid item xs="auto">
                    <ResendWaiverButton appointment_id={appointment.id ?? 0} />
                </Grid>
            )}
        </Grid>
    );
};

export default AppointmentNotifications;
