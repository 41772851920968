import { Grid } from '@mui/material';
import TwoColumnsSwitch from '../../ui-component/TwoColumnsSwitch';
import InfoTooltip from '../../ui-component/InfoTooltip';
import { FC } from 'react';
import LabelWithInfo from '../../ui-component/LabelWithInfo';
import OptimizedSchedule from '../../ui-component/optimized-text-fields/schedule/OptimizedSchedule';
import { ISchedule } from '../../models/ILocation';
import { FormikErrors } from 'formik';
import StaffShifts from './StaffShifts';
import { EmployeeSubFormValueType } from './use-employee-form-schema';

interface IEmployeeLocationSubformProps {
    values: EmployeeSubFormValueType;
    valuePrefix: string;
    setFieldValue: (field: string, value: any) => void;
    errors?: FormikErrors<EmployeeSubFormValueType>;
}

const EmployeeLocationSubform: FC<IEmployeeLocationSubformProps> = ({ values, valuePrefix, setFieldValue, errors }) => (
    <>
        <TwoColumnsSwitch
            value={values.use_location_schedule}
            fieldName={`${valuePrefix}.use_location_schedule`}
            setFieldValue={setFieldValue}
            label="Use Location Schedule"
            labelDecoration={
                <InfoTooltip text="If enabled, location settings for open hours and time are used for this provider. If provider is part of multiple locations, availability depends on which location is selected in the widget. Disable to set individual schedule for this provider. Individual schedule overrides location settings and is applied for all locations that provider is part of." />
            }
        />

        {!values.use_location_schedule && (
            <>
                <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                    <LabelWithInfo label="Schedule" infoText="Set days and time when this provider is available for booking." />
                </Grid>
                <Grid item xs={12} sm={9} lg={8} sx={{ overflow: 'auto' }}>
                    <OptimizedSchedule
                        schedules={values.schedule}
                        onChange={(schedules: ISchedule[]) => {
                            setFieldValue(`${valuePrefix}.schedule`, schedules);
                        }}
                        errors={errors}
                    />
                </Grid>
            </>
        )}
        <StaffShifts
            setFieldValue={setFieldValue}
            is_shifts_enabled={values.is_shifts_enabled}
            checkboxFieldName={`${valuePrefix}.is_shifts_enabled`}
            shifts={values.shifts}
            setShifts={(shifts) => {
                setFieldValue(`${valuePrefix}.shifts`, shifts);
            }}
            error={typeof errors?.shifts === 'string' ? errors.shifts : null}
            errors={errors}
            label="Custom Shifts"
            tooltip="Custom shifts allow you to override regular schedule (location-based and individual) by adding specific dates when provider is not available, or when their schedule is different from normal."
        />
    </>
);

export default EmployeeLocationSubform;
