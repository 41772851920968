import * as Yup from 'yup';
import { isValidPhone } from '../../utils/phone-helpers';
import { IEmployeeLocation, IGroupedCustomShift } from '../../models/IEmployee';
import { useMemo } from 'react';
import serviceAPI from '../../services/ServiceService';
import { PaymentType } from '../../models/IService';
import ShiftScheme from '../../ui-component/form/schemes/shift-scheme';

export type EmployeeSubFormValueType = Pick<IEmployeeLocation['pivot'], 'use_location_schedule' | 'schedule' | 'is_shifts_enabled'> & {
    shifts: IGroupedCustomShift[];
};

const LocationShiftScheme = Yup.object().shape({
    id: Yup.number().required(),
    pivot: Yup.object()
        .shape({
            schedule: Yup.mixed(),
            is_shifts_enabled: Yup.boolean(),
            use_location_schedule: Yup.boolean(),
            shifts: Yup.array().when('is_shifts_enabled', {
                is: true,
                then: Yup.array().of(ShiftScheme).min(1, 'At least one item is required'),
                otherwise: Yup.array().nullable()
            })
        })
        .required()
});

const useEmployeeFormSchema = (isEdit: boolean) => {
    const services = serviceAPI.useFetchAllServicesQuery({});
    const serviceOptions = services?.data?.data;

    const employeeServiceSchema = useMemo(
        () =>
            Yup.object().shape({
                id: Yup.number().integer().positive().required(),
                has_price_override: Yup.boolean(),
                has_duration_override: Yup.boolean(),
                has_commission_override: Yup.boolean(),
                price: Yup.mixed()
                    .when('has_price_override', {
                        is: true,
                        then: Yup.number()
                            .typeError('Price must be a number')
                            .required('Price is required')
                            // eslint-disable-next-line func-names
                            .test('price', '', function (v) {
                                const { path, parent, createError } = this;
                                const linkedService = serviceOptions?.find((service) => service.id === parent.id);
                                const minimalValue = linkedService?.payment_type === PaymentType.Free ? 0 : 0.01;
                                const valueToCompare = v !== undefined ? parseFloat(String(v)) : 0;
                                const isValid = valueToCompare >= minimalValue;

                                return (
                                    isValid ||
                                    createError({
                                        path,
                                        message: `Price must be greater or equal to ${minimalValue}`
                                    })
                                );
                            })
                            .test('price', 'Price must be greater than or equal to prepaid service deposit amount', (v, ctx) => {
                                const linkedService = serviceOptions?.find((service) => service.id === ctx.parent.id);
                                if (linkedService?.payment_type === PaymentType.Prepaid && linkedService?.prepay) {
                                    return (
                                        (v ? parseFloat(String(v)) : 0) >=
                                        (linkedService.prepay ? parseFloat(String(linkedService.prepay)) : 0)
                                    );
                                }

                                return true;
                            })
                            .label('Price')
                    })
                    .nullable(true)
                    .transform((v) => (v === '' ? null : v))
                    .label('Price'),
                duration: Yup.mixed().when('has_duration_override', {
                    is: true,
                    then: Yup.number().required().min(10).max(600).label('Duration')
                }),
                commission_type: Yup.mixed().when('has_commission_override', {
                    is: true,
                    then: Yup.string()
                        .trim()
                        .required('Commission type is required')
                        .oneOf(['fixed', 'percent'], 'Commission type is required')
                }),
                commission_amount: Yup.mixed().when('has_commission_override', {
                    is: true,
                    then: Yup.number()
                        .typeError('Commission amount is required')
                        .required('Commission amount is required')
                        .min(0, 'Commission amount is required')
                        .test('commission_amount', "Commission amount can't be greater then 100%", (v, ctx) => {
                            if (ctx.parent.commission_type === 'percent' && !!v) {
                                return v <= 100;
                            }

                            return true;
                        })
                })
            }),
        [serviceOptions]
    );

    const employeeUserSchema = useMemo(() => {
        if (isEdit) {
            return Yup.object().shape({
                firstname: Yup.mixed(),
                lastname: Yup.mixed(),
                email: Yup.mixed(),
                phone: Yup.mixed()
            });
        }

        return Yup.object().shape({
            firstname: Yup.string().max(255, 'First name must be at most 255 characters').required('First name is required'),
            lastname: Yup.string().max(255, 'Last name must be at most 255 characters').required('Last name is required'),
            email: Yup.string()
                .max(255, 'Email must be at most 255 characters')
                .required('Email is required')
                .email('Must be a valid email'),
            phone: Yup.string()
                .nullable()
                .test({
                    name: 'phone',
                    test: (v) => (v ? isValidPhone(v) : true),
                    message: 'Phone number is not valid'
                })
        });
    }, [isEdit]);

    return useMemo(
        () =>
            Yup.object().shape({
                user: employeeUserSchema,
                role: Yup.string().required('Role is required'),
                profession_title: Yup.string().nullable().max(255, 'Profession title must be at most 255 characters'),
                self_book: Yup.boolean(),
                locations: Yup.array().when('self_book', {
                    is: true,
                    then: Yup.array().min(1, 'At least one Location is required'),
                    otherwise: Yup.array().nullable()
                }),
                location_shifts: Yup.array().of(LocationShiftScheme),
                services: Yup.array().when('self_book', {
                    is: true,
                    then: Yup.array().of(employeeServiceSchema).min(1, 'At least one Service is required'),
                    otherwise: Yup.array().nullable()
                }),
                schedule: Yup.mixed(),
                settings: Yup.object().shape({
                    widget: Yup.object()
                        .shape({
                            accounting_google_events: Yup.boolean().nullable()
                        })
                        .nullable(),
                    integrations: Yup.object()
                        .shape({
                            use_provider_payment_details: Yup.boolean()
                        })
                        .notRequired()
                        .nullable()
                }),
                products: Yup.array().of(
                    Yup.object().shape({
                        id: Yup.number().required(),
                        pivot: Yup.object().shape({
                            commission_type: Yup.string().trim().nullable(),
                            commission_amount: Yup.mixed()
                                .when('commission_type', {
                                    is: 'percent',
                                    then: Yup.number()
                                        .typeError('Commission Amount is required')
                                        .min(0)
                                        .max(100)
                                        .required('Commission Amount is required')
                                })
                                .when('commission_type', {
                                    is: (v: string | null) => !v !== null,
                                    then: Yup.number()
                                        .typeError('Commission Amount is required')
                                        .min(0)
                                        .required('Commission Amount is required')
                                })
                                .nullable()
                                .notRequired()
                        })
                    })
                )
            }),
        [employeeServiceSchema, employeeUserSchema]
    );
};

export default useEmployeeFormSchema;
