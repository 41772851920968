import { FC, FocusEvent } from 'react';
import { Divider, Grid, InputAdornment } from '@material-ui/core';
import LabelWithInfo from '../../ui-component/LabelWithInfo';
import NumberFormat from 'react-number-format';
import FocusTextField from '../../ui-component/FocusTextField';

export interface ISettingsFormDelayRowProps {
    label: string;
    fieldKey: string;
    value: number;
    error?: string | null;
    onChange: (field: string, value: unknown) => void;
    onBlur: (e: FocusEvent) => void;
    scale?: 'Hours' | 'Minutes';
    infoText?: string;
}

const SettingsFormDelayRow: FC<ISettingsFormDelayRowProps> = ({
    label,
    fieldKey,
    value,
    error,
    onChange,
    onBlur,
    infoText,
    scale = 'Hours'
}) => (
    <>
        <Grid item xs={12} sm={3} lg={4}>
            <LabelWithInfo label={label} infoText={infoText} />
        </Grid>
        <Grid item xs={12} sm={9} lg={6}>
            <NumberFormat
                customInput={FocusTextField}
                fullWidth
                id={fieldKey}
                name={fieldKey}
                value={value}
                error={!!error}
                helperText={error}
                decimalScale={0}
                onValueChange={(val) => onChange(fieldKey, val?.floatValue ?? '')}
                onBlur={onBlur}
                InputProps={{
                    startAdornment: (
                        <>
                            <InputAdornment position="start">{scale}</InputAdornment>
                            <Divider sx={{ height: 28, m: 0.5, mr: 1.5 }} orientation="vertical" />
                        </>
                    )
                }}
                allowNegative={false}
            />
        </Grid>
    </>
);

export default SettingsFormDelayRow;
