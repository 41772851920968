import { Grid } from '@mui/material';
import LabelWithInfo from '../LabelWithInfo';
import { FC, PropsWithChildren, ReactChild } from 'react';

interface ICommonFormRowProps {
    label: string;
    infoText?: ReactChild;
}

const CommonFormRow: FC<PropsWithChildren<ICommonFormRowProps>> = ({ children, label, infoText }) => (
    <>
        <Grid item xs={12} sm={3} lg={4}>
            <LabelWithInfo label={label} infoText={infoText} />
        </Grid>
        <Grid item xs={12} sm={9} lg={6}>
            {children}
        </Grid>
    </>
);

export default CommonFormRow;
