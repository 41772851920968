import useAuth from '../../hooks/useAuth';
import companyAPI from '../../services/CompanyService';
import SettingsForm from './SettingsForm';
import ErrorCatcher from '../../ui-component/error-catcher';

const Settings = () => {
    const { user } = useAuth();
    const { data, isFetching } = companyAPI.useGetCompanyQuery(user?.currentCompany.id.toString() || '', {
        refetchOnMountOrArgChange: true
    });

    return !isFetching && data ? (
        <ErrorCatcher wrappedComponentName="Settings">
            <SettingsForm company={data} />
        </ErrorCatcher>
    ) : null;
};

export default Settings;
