import { Box, Link, Stack } from '@mui/material';
import AppointmentCustomerForm from './elements/AppointmentCustomerForm';
import SectionHeading from '../../SectionHeading';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux';
import { useCallback, useMemo } from 'react';
import CustomerAppointments from '../../../../../../../ui-component/customer-appointments';
import { UserRole } from '../../../../../../../models/IEmployee';
import useAuth from '../../../../../../../hooks/useAuth';
import moment from 'moment-timezone';
import { appDateFormat } from '../../../../../../../store/constant';
import AppointmentNotifications from '../../../../../../../ui-component/appointment-form/elements/AppointmentNotifications';
import { closeDialog, DialogTypes, openDialog } from '../../../../../../../store/slices/entityDialogsSlice';
import DeleteWaiverButton from '../../../../components/DeleteWaiverButton';

interface ICustomerInfoProps {
    onResetTabs: () => void;
}

const CustomerInfo = ({ onResetTabs }: ICustomerInfoProps) => {
    const { user } = useAuth();
    const { selectedEvent, isForeignAppointment } = useAppSelector((state) => state.calendar);

    const dispatch = useAppDispatch();

    const handleViewAppointment = useCallback(
        (id: number) => {
            dispatch(closeDialog());
            dispatch(openDialog({ type: DialogTypes.Appointment, id }));
            onResetTabs();
        },
        [dispatch, onResetTabs]
    );

    const hasWaiver = selectedEvent?.has_waiver;
    const eventSignedWaivers = selectedEvent?.signed_waiver;
    const role = user?.employee.role.name;
    const currentUserId = user?.employee.id;
    const eventEmployeeId = selectedEvent?.employee.id;

    const isWaiverVisibleToUser = useMemo(() => {
        if (role === UserRole.Provider) {
            return eventEmployeeId === currentUserId;
        }
        return true;
    }, [currentUserId, eventEmployeeId, role]);

    return (
        <Box px={2} display="flex" flexDirection="column">
            <AppointmentCustomerForm />
            {!!hasWaiver && !!eventSignedWaivers && eventSignedWaivers.length > 0 && isWaiverVisibleToUser && (
                <Box>
                    <SectionHeading mt={2} mb={1}>
                        Forms
                    </SectionHeading>
                    {eventSignedWaivers.map((waiver) => (
                        <Box key={waiver.service_id} mb={0.5}>
                            <Link href={waiver.url ?? ''} type="download" target="_blank" sx={{ mr: 0.5 }}>
                                {`${waiver.waiver_name ?? 'Untitled'} (${moment(waiver.waiver_signed_at).format(appDateFormat)})`}
                            </Link>
                            <DeleteWaiverButton serviceId={waiver.service_id} appointmentId={selectedEvent?.id ?? ''} />
                        </Box>
                    ))}
                </Box>
            )}

            {selectedEvent ? (
                <>
                    <SectionHeading mt={2} mb={1}>
                        Appointment Notifications
                    </SectionHeading>
                    <Stack direction="row" spacing={2}>
                        <AppointmentNotifications appointment={selectedEvent} isReadOnly={isForeignAppointment} />
                    </Stack>
                </>
            ) : null}

            <SectionHeading mt={2} mb={1}>
                Customer Appointments
            </SectionHeading>
            {selectedEvent && <CustomerAppointments customerId={selectedEvent.customer.id} onViewAppointment={handleViewAppointment} />}
        </Box>
    );
};

export default CustomerInfo;
