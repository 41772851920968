import { FC, useMemo, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import companyAPI from '../../../services/CompanyService';
import AppPagePagination from '../../../ui-component/PagePagination';
import NewWebhook from './NewWebhook';
import WebhookTableRow from './WebhookTableRow';
import CBTable from '../../../ui-component/tables/CBTable';
import { IWebhookItem } from '../types';

const WebhooksSettingsTable: FC<{ companyId: number }> = ({ companyId }) => {
    const [page, setPage] = useState<number>(1);
    const { data, isLoading } = companyAPI.useGetCompanyWebhooksQuery({ companyId, params: { page, per_page: 5 } });
    const companyWebhooksData = data?.data;

    const rows = useMemo<IWebhookItem[]>(() => {
        const arr: IWebhookItem[] = [];
        return companyWebhooksData ?? arr;
    }, [companyWebhooksData]);

    return (
        <Box>
            <Stack direction="row" spacing={2}>
                <Typography variant="h4" color="primary" sx={{ flexGrow: 1, flexShrink: 1 }}>
                    Web Hooks
                </Typography>
                <NewWebhook companyId={companyId} />
            </Stack>

            <CBTable
                columns={['Name', 'Enabled', 'URL', '']}
                rows={rows}
                isLoading={isLoading}
                renderRow={(row) => <WebhookTableRow companyId={companyId} row={row} key={row.id} />}
            />

            <Grid container spacing={3} sx={{ pt: 2 }}>
                <AppPagePagination data={data} perPage={5} page={page} setPage={setPage} />
            </Grid>
        </Box>
    );
};

export default WebhooksSettingsTable;
