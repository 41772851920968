import companyAPI from '../../../services/CompanyService';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch } from '../../../hooks/redux';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import { startSubmitting, stopSubmitting } from '../../../store/slices/SubmittingSlice';
import CBModal from '../../../ui-component/cb-modal/CBModal';
import ApiKeyForm from './ApiKeyForm';
import { Theme, useMediaQuery } from '@material-ui/core';
import { ICompanyApiKey, ICompanyApiKeyPayload } from '../types';

const ApiKeyTableRow: FC<{ row: ICompanyApiKey; companyId: number }> = ({ row, companyId }) => {
    const dispatch = useAppDispatch();
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const { showSnackbar } = useShowSnackbar();
    const [deleteKey, { isLoading: isDeleting }] = companyAPI.useDeleteCompanyApiKeyMutation();
    const [updateKey, { isLoading: isUpdating }] = companyAPI.useUpdateCompanyApiKeyMutation();
    const [edit, setEdit] = useState(false);

    const actionsDisabled = useMemo(() => isDeleting || isUpdating, [isDeleting, isUpdating]);
    const formatDate = useCallback((date: string | null) => (date ? moment(date).format('YYYY-MM-DD HH:mm') : null), []);

    const onDelete = useCallback(() => {
        dispatch(
            openConfirmPopup({
                title: 'Deleting API Key',
                text: 'Are you sure you want to delete this API key?',
                confirmText: 'Confirm',
                onConfirm: () => {
                    deleteKey({ companyId, keyId: row.id })
                        .unwrap()
                        .then(() => {
                            showSnackbar({
                                alertSeverity: SnackBarTypes.Success,
                                message: 'API Key successfully deleted'
                            });
                        })
                        .catch((err) => {
                            showSnackbar({
                                alertSeverity: SnackBarTypes.Error,
                                message: err.data ?? err.message ?? JSON.stringify(err)
                            });
                        });
                }
            })
        );
    }, [companyId, deleteKey, dispatch, row.id, showSnackbar]);

    const onUpdate = useCallback(
        (formData: ICompanyApiKeyPayload) => {
            dispatch(startSubmitting());
            updateKey({ companyId, keyId: row.id, data: formData })
                .unwrap()
                .then(() => {
                    setEdit(false);
                    showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'API Key successfully updated' });
                })
                .catch((err) => {
                    showSnackbar({
                        alertSeverity: SnackBarTypes.Error,
                        message: err.data ?? err.message ?? JSON.stringify(err)
                    });
                })
                .finally(() => {
                    dispatch(stopSubmitting());
                });
        },
        [companyId, dispatch, row.id, showSnackbar, updateKey]
    );

    return (
        <>
            <TableRow>
                <TableCell>{row.name}</TableCell>
                <TableCell>{formatDate(row.created_at)}</TableCell>
                <TableCell>{formatDate(row.last_used_at) ?? 'Never'}</TableCell>
                <TableCell sx={{ textAlign: 'right', py: 0 }}>
                    <IconButton color="primary" size="small" disabled={actionsDisabled} onClick={() => setEdit(true)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="error" size="small" onClick={onDelete} disabled={actionsDisabled}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <CBModal
                open={edit}
                onClose={() => setEdit(false)}
                title="Edit API Key"
                okButtonText="Save"
                okButtonFormId="api-key-form"
                okButtonDisabled={isUpdating}
                maxWidth="sm"
                fullWidth
                fullScreen={matchSm}
            >
                <ApiKeyForm onSubmit={onUpdate} defaults={{ name: row.name }} />
            </CBModal>
        </>
    );
};

export default ApiKeyTableRow;
