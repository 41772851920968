import { useParams } from 'react-router';
import waiverAPI from '../../services/WaiverService';
import WaiverCreator from './WaiverCreator';
import { Button, CircularProgress, Stack } from '@material-ui/core';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate } from 'react-router-dom';
import ErrorCatcher from '../../ui-component/error-catcher';

const WaiverEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { data, isLoading } = waiverAPI.useGetWaiverQuery(id || '', {
        refetchOnMountOrArgChange: true
    });

    return (
        <ErrorCatcher wrappedComponentName="WaiverEdit">
            <MainCard
                title="Form Builder"
                secondary={
                    <Button size="small" disableElevation onClick={() => navigate(-1)}>
                        <ChevronLeftOutlinedIcon />
                        Go back
                    </Button>
                }
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
                contentSX={{ p: { xs: 1.5, sm: 3 }, flexGrow: 1 }}
            >
                {!isLoading && data ? (
                    <WaiverCreator initData={data} />
                ) : (
                    <Stack justifyContent="center" direction="row" sx={{ mx: 'auto', mt: 1, width: 200 }}>
                        <CircularProgress />
                    </Stack>
                )}
            </MainCard>
        </ErrorCatcher>
    );
};

export default WaiverEdit;
