import { useCallback, useMemo } from 'react';
import { IAppointmentPayload } from '../../../../../models/IAppointment';
import appointmentAPI from '../../../../../services/AppointmentService';
import { SnackBarTypes } from '../../../../../store/snackbarReducer';
import { startSubmitting, stopSubmitting } from '../../../../../store/slices/SubmittingSlice';
import useShowSnackbar from '../../../../../hooks/useShowSnackbar';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';

const useSaveAppointment = () => {
    const { showSnackbar } = useShowSnackbar();
    const dispatch = useAppDispatch();
    const { entityId } = useAppSelector((state) => state.entityDialogs);
    const [updateAppointment, { isLoading: isSaving }] = appointmentAPI.useUpdateAppointmentMutation();
    const [createAppointment, { isLoading: isCreating }] = appointmentAPI.useCreateAppointmentMutation();

    const saveAppointment = useCallback(
        (
            data: IAppointmentPayload,
            callback?: () => void,
            appointmentId?: string,
            finalCallback?: () => void,
            errorCb?: (e: { data: string; errors: Record<string, string | string[]> }) => void
        ) => {
            const save = appointmentId ? updateAppointment({ appointmentId, data }) : createAppointment(data);
            const matchWithCurrentEntityId = appointmentId && entityId ? String(entityId) === String(appointmentId) : true;

            if (matchWithCurrentEntityId) {
                dispatch(startSubmitting());
                save.unwrap()
                    .then(() => {
                        if (callback) callback();
                    })
                    .catch((e: { data: string; errors: Record<string, string | string[]> }) => {
                        if (errorCb && e.errors) {
                            errorCb(e);
                        }

                        showSnackbar({
                            message: e.data || 'Appointment was not updated',
                            alertSeverity: SnackBarTypes.Error
                        });
                    })
                    .finally(() => {
                        dispatch(stopSubmitting());
                        if (finalCallback) finalCallback();
                    });
            } else {
                dispatch(stopSubmitting());
                showSnackbar({
                    alertSeverity: SnackBarTypes.Error,
                    message:
                        'Something went wrong, please reload the page and try again later. Tech details: appointmentId does not match entityId'
                });
            }
        },
        [createAppointment, dispatch, entityId, showSnackbar, updateAppointment]
    );

    const isSavingAppointment = useMemo(() => isSaving || isCreating, [isSaving, isCreating]);

    return { saveAppointment, isSavingAppointment };
};

export default useSaveAppointment;
