import { FC } from 'react';
import { Box, Button, Tooltip } from '@mui/material';

const SelfCheckoutTrigger: FC<{ available: boolean; disabled?: boolean; onClick: () => void }> = ({ available, disabled, onClick }) => (
    <Tooltip title={!available ? 'Please set up Twillio and payment integration to use this feature.' : ''}>
        <Box component="span" sx={{ alignSelf: 'flex-start', flexShrink: 0 }}>
            <Button
                onClick={onClick}
                variant="outlined"
                size="large"
                disabled={!available || disabled}
                sx={{ minHeight: '44px', borderRadius: '8px' }}
            >
                Self Checkout
            </Button>
        </Box>
    </Tooltip>
);

export default SelfCheckoutTrigger;
