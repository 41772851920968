import Transitions from '../../../../ui-component/extended/Transitions';
import {
    Box,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import MainCard from '../../../../ui-component/cards/MainCard';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import NotificationList from './NotificationList';
import { FC, MouseEvent as ReactMouseEvent, MutableRefObject } from 'react';
import { PopperProps } from '@mui/material';
import { INotification } from '../../../../models/INotification';
import { Theme, useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

interface INotificationsPopperProps {
    open: boolean;
    anchorRef: MutableRefObject<HTMLElement | null>;
    setOpen: (v: boolean) => void;
    placement: PopperProps['placement'];
    data?: INotification[];
    notificationsLength: number;
    unreadNotificationsLength: number;
    onReadAll: () => void;
}

// style constant
const useStyles = makeStyles((theme: Theme) => ({
    ScrollHeight: {
        height: '100%',
        maxHeight: 'calc(100vh - 205px)',
        overflowX: 'hidden'
    },
    cardContent: {
        padding: '0px !important',
        overflowX: 'hidden'
    },
    divider: {
        marginTop: 0,
        marginBottom: 0
    }
}));

const NotificationsPopper: FC<INotificationsPopperProps> = ({
    open,
    anchorRef,
    placement,
    setOpen,
    data,
    notificationsLength,
    unreadNotificationsLength,
    onReadAll
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = (event: ReactMouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        setOpen(false);
    };

    return (
        <Popper
            placement={placement}
            open={open}
            anchorEl={anchorRef?.current}
            role={undefined}
            transition
            style={{
                zIndex: 1200
            }}
            popperOptions={{
                strategy: 'fixed',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [matchesXs ? 5 : 0, 20]
                        }
                    }
                ]
            }}
        >
            {({ TransitionProps }) => (
                <Transitions in={open} {...TransitionProps}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                <CardContent className={classes.cardContent}>
                                    <Box sx={{ padding: '18px 20px' }}>
                                        <Grid container alignItems="center" justifyContent="space-between">
                                            <Grid item>
                                                {notificationsLength ? (
                                                    <Stack direction="row" spacing={0.5} alignItems="center">
                                                        <Typography variant="subtitle1" sx={{ fontSize: '16px' }}>
                                                            Notifications
                                                        </Typography>
                                                        <Chip
                                                            size="small"
                                                            label={unreadNotificationsLength}
                                                            color={
                                                                unreadNotificationsLength && unreadNotificationsLength > 0
                                                                    ? 'error'
                                                                    : 'warning'
                                                            }
                                                        />
                                                    </Stack>
                                                ) : (
                                                    <Typography variant="subtitle1">No notifications yet</Typography>
                                                )}
                                            </Grid>
                                            {!!unreadNotificationsLength && unreadNotificationsLength > 0 && (
                                                <Grid item onClick={onReadAll}>
                                                    <Typography
                                                        component={Link}
                                                        to="#"
                                                        variant="subtitle2"
                                                        color="primary"
                                                        sx={{ fontSize: '16px', fontWeight: 700 }}
                                                    >
                                                        Mark all as read
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <PerfectScrollbar className={classes.ScrollHeight} options={{ suppressScrollX: true }}>
                                            <Box>
                                                {!!notificationsLength && notificationsLength > 0 && (
                                                    <>
                                                        <Divider className={classes.divider} />
                                                        <NotificationList notifications={data} setOpen={setOpen} />
                                                    </>
                                                )}
                                            </Box>
                                        </PerfectScrollbar>
                                    </Box>
                                </CardContent>
                            </MainCard>
                        </ClickAwayListener>
                    </Paper>
                </Transitions>
            )}
        </Popper>
    );
};

export default NotificationsPopper;
