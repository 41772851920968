import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux';
import SectionHeading from '../../SectionHeading';
import AddIconButton from '../../../../../../../ui-component/AddIconButton';
import PaymentForm from './elements/PaymentForm';
import { Box, Stack } from '@mui/material';
import { IPayment } from '../../../../../../../models/IPayment';
import { startSubmitting, stopSubmitting } from '../../../../../../../store/slices/SubmittingSlice';
import { SnackBarTypes } from '../../../../../../../store/snackbarReducer';
import useShowSnackbar from '../../../../../../../hooks/useShowSnackbar';
import appointmentAPI from '../../../../../../../services/AppointmentService';
import { openConfirmPopup } from '../../../../../../../store/confirmPopupSlice';
import PaymentsTable from './elements/payments-table';
import PaymentsSummary from './elements/payments-summary';
import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { getServiceName } from '../../../../../../../utils/services';
import QrCodeDialogToggle from '../../../../../../../ui-component/QrCodeDialogToggle';
import ConditionalDialog from '../../../../../../../ui-component/conditional-dialog/ConditionalDialog';
import SelfCheckoutForm from '../../../../../../../ui-component/self-checkout/self-checkout-form';
import SelfCheckoutTrigger from '../../../../../../../ui-component/self-checkout/self-checkout-trigger';
import QRCode from 'react-qr-code';
import NewPaymentForm from './elements/new-payment-form';
import { useForceMobileLayoutContext } from '../../../../../../../ui-component/force-mobile-layout-context';

const Payments = () => {
    const { selectedEvent, isForeignAppointment } = useAppSelector((state) => state.calendar);
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const forceMobile = useForceMobileLayoutContext();
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));

    const [editPayment] = appointmentAPI.useUpdateAppointmentPaymentMutation();
    const [createPayment] = appointmentAPI.useCreateAppointmentPaymentMutation();
    const [deletePayment] = appointmentAPI.useDeleteAppointmentPaymentMutation();

    const [showForm, setShowForm] = useState<boolean>(false);
    const [showSelfCheckout, setShowSelfCheckout] = useState(false);
    const [showQr, setShowSQr] = useState(false);

    const handleCloseSelfCheckout = useCallback(() => {
        setShowSelfCheckout(false);
    }, []);

    const handleOpenSelfCheckout = useCallback(() => {
        setShowSelfCheckout(true);
    }, []);

    const [editablePaymentIndex, setEditablePaymentIndex] = useState<number | null>(null);

    const openCleanForm = useCallback(() => {
        setEditablePaymentIndex(null);
        setShowForm(true);
    }, []);

    const closeForm = useCallback(() => {
        setEditablePaymentIndex(null);
        setShowForm(false);
    }, []);

    const onError = useCallback(
        (e) => {
            showSnackbar({
                message: e.data || "Payment hasn't been Updated",
                alertSeverity: SnackBarTypes.Error
            });
        },
        [showSnackbar]
    );

    const onFinally = useCallback(() => {
        dispatch(stopSubmitting());
    }, [dispatch]);

    const savePayments = useCallback(
        (data: IPayment) => {
            if (!selectedEvent) return;

            if (editablePaymentIndex !== null) {
                dispatch(startSubmitting());
                editPayment({
                    appointmentId: selectedEvent.id.toString(),
                    data: Array.isArray(data) ? data[0] : data,
                    paymentIndex: editablePaymentIndex
                })
                    .unwrap()
                    .then(() => {
                        closeForm();
                    })
                    .catch(onError)
                    .finally(onFinally);
            }
        },
        [selectedEvent, dispatch, editablePaymentIndex, editPayment, onError, onFinally, closeForm]
    );

    const handleCreatePayments = useCallback(
        (id: string, payments: IPayment[]) => {
            dispatch(startSubmitting());
            createPayment({
                appointmentId: id,
                payments
            })
                .unwrap()
                .then(closeForm)
                .catch(onError)
                .finally(onFinally);
        },
        [closeForm, createPayment, dispatch, onError, onFinally]
    );

    const handleDeletePayment = useCallback(
        (index: number) => {
            dispatch(
                openConfirmPopup({
                    onConfirm: () => {
                        if (selectedEvent) {
                            dispatch(startSubmitting());
                            deletePayment({
                                appointmentId: selectedEvent.id.toString(),
                                paymentIndex: index
                            })
                                .unwrap()
                                .catch(onError)
                                .finally(onFinally);
                        }
                    },
                    confirmText: `Delete`,
                    text: `Are you sure you want to delete this payment?`
                })
            );
        },
        [dispatch, selectedEvent, deletePayment, onError, onFinally]
    );

    const onClickEdit = useCallback((index: number) => {
        setEditablePaymentIndex(index);
        setShowForm(true);
    }, []);

    const showMainContent = useMemo(() => !showForm && !showSelfCheckout && !showQr, [showForm, showSelfCheckout, showQr]);
    const showSummary = useMemo(() => !showSelfCheckout && !showQr, [showSelfCheckout, showQr]);

    return (
        <Box display="flex" flexDirection="column" height="100%">
            <Stack
                spacing={1.5}
                px={2}
                mb={1}
                alignItems="center"
                direction={matchSm || forceMobile ? 'column' : 'row'}
                sx={{ width: '100%', minHeight: '44px' }}
            >
                <SectionHeading sx={{ flexGrow: 1, flexShrink: 1 }}>
                    {getServiceName(selectedEvent?.services || []).fullValue}
                </SectionHeading>
                {showMainContent ? (
                    <Stack spacing={1.5} direction="row" alignSelf="flex-start" sx={{ flexGrow: 0, flexShrink: 0 }}>
                        {selectedEvent?.self_checkout_url && !isForeignAppointment ? (
                            <QrCodeDialogToggle onClick={() => setShowSQr(true)} />
                        ) : null}
                        {selectedEvent ? (
                            <SelfCheckoutTrigger
                                onClick={handleOpenSelfCheckout}
                                available={!!selectedEvent?.is_self_checkout_available}
                                disabled={isForeignAppointment || selectedEvent.balance_details?.balance_remaining === 0}
                            />
                        ) : null}
                        <AddIconButton
                            onClick={openCleanForm}
                            disabled={isForeignAppointment}
                            sx={{ alignSelf: 'flex-start', flexGrow: 0, flexShrink: 0 }}
                        />
                    </Stack>
                ) : null}
            </Stack>

            <Box overflow="auto" sx={{ flexGrow: 1 }}>
                {showMainContent ? <PaymentsTable onClickDelete={handleDeletePayment} onClickEdit={onClickEdit} /> : null}

                <Box px={2}>
                    <ConditionalDialog
                        title={editablePaymentIndex !== null ? 'Edit Payment' : undefined}
                        open={showForm}
                        formId="payment-info-form"
                        onCancel={closeForm}
                        okButtonLabel={editablePaymentIndex !== null ? 'Confirm' : 'Add Payment'}
                        actionsRowSx={editablePaymentIndex === null ? { display: 'none' } : undefined}
                    >
                        {editablePaymentIndex === null ? (
                            <NewPaymentForm onSubmit={handleCreatePayments} onCancel={closeForm} />
                        ) : (
                            <PaymentForm paymentIndex={editablePaymentIndex} savePayments={savePayments} />
                        )}
                    </ConditionalDialog>

                    {selectedEvent ? (
                        <SelfCheckoutForm
                            open={showSelfCheckout}
                            onClose={handleCloseSelfCheckout}
                            phone={selectedEvent.customer.phone}
                            appointmentId={selectedEvent.id}
                        />
                    ) : null}

                    {selectedEvent?.self_checkout_url ? (
                        <ConditionalDialog
                            open={showQr}
                            onCancel={() => setShowSQr(false)}
                            title="Link to Self Checkout"
                            cancelBtnLabel="Close"
                            actionsRowSx={{ justifyContent: 'center' }}
                            headerSx={{ textAlign: 'center' }}
                        >
                            <Box display="flex" justifyContent="center">
                                <QRCode value={selectedEvent?.self_checkout_url ?? ''} />
                            </Box>
                        </ConditionalDialog>
                    ) : null}
                </Box>
            </Box>

            {showSummary ? <PaymentsSummary /> : null}
        </Box>
    );
};

export default Payments;
