import { FC, useCallback } from 'react';
import { FormControlLabel, FormHelperText, Grid, MenuItem, TextField, Switch, Autocomplete } from '@mui/material';
import { IWebhookSettingsInput } from '../types';
import * as Yup from 'yup';
import useExtendedFormik from '../../../hooks/useExtendedFormik';
import useServiceOptions from '../../../hooks/options/useServiceOptions';

interface WebhookSettingsFormProps {
    defaults: IWebhookSettingsInput;
    onSave: (formData: IWebhookSettingsInput) => void;
    triggersData: Record<string, string>;
}

const schema = Yup.object().shape({
    name: Yup.string().trim().required().label('Name'),
    description: Yup.string().trim().nullable(),
    is_enabled: Yup.boolean().required(),
    events: Yup.object()
        .required()
        .test('events', 'At least one event must be selected', (v) => Object.values(v).some((val) => !!val)),
    settings: Yup.object().shape({
        url: Yup.string().trim().url().required().label('Settings Url'),
        method: Yup.string().trim().required().oneOf(['GET', 'POST']).label('Settings Method'),
        secret: Yup.string().trim().nullable()
    }),
    service_ids: Yup.array().of(Yup.number()).nullable()
});

const WebhookSettingsForm: FC<WebhookSettingsFormProps> = ({ defaults, onSave, triggersData }) => {
    const { values, errors, touched, handleBlur, handleChange, setFieldValue, handleSubmit } = useExtendedFormik<IWebhookSettingsInput>({
        enableReinitialize: true,
        initialValues: defaults,
        onSubmit: onSave,
        validationSchema: schema
    });

    const { services, getNameById, isLoading } = useServiceOptions();

    const onServiceChange = useCallback(
        (e: unknown, v: number[] | null) => {
            setFieldValue('service_ids', v ?? []);
        },
        [setFieldValue]
    );

    return (
        <Grid id="webhook-form" container spacing={2} component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid item xs={12} sm={3}>
                Name
            </Grid>
            <Grid item xs={12} sm={9}>
                <TextField
                    fullWidth
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!touched.name && !!errors.name}
                    helperText={touched.name ? errors.name : undefined}
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                Description
            </Grid>

            <Grid item xs={12} sm={9}>
                <TextField
                    fullWidth
                    id="description"
                    name="description"
                    value={values.description ?? ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline
                    minRows={4}
                    maxRows={4}
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description ? errors.description : undefined}
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                Enabled
            </Grid>

            <Grid item xs={12} sm={9}>
                <FormControlLabel
                    control={
                        <Switch
                            aria-label="Enabled"
                            checked={values.is_enabled}
                            name="is_enabled"
                            onChange={(_, checked) => {
                                setFieldValue('is_enabled', checked);
                            }}
                        />
                    }
                    label=""
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                Events
            </Grid>

            <Grid item xs={12} sm={9}>
                {triggersData &&
                    Object.entries(triggersData).map(([key, value]) => (
                        <FormControlLabel
                            key={key}
                            control={
                                <Switch
                                    checked={Boolean(values.events[key])}
                                    name={`events.${key}`}
                                    onChange={(_, checked) => {
                                        setFieldValue(`events.${key}`, checked);
                                    }}
                                />
                            }
                            label={value}
                        />
                    ))}
                {!!errors.events && touched.events ? <FormHelperText error>{errors.events}</FormHelperText> : null}
            </Grid>

            <Grid item xs={12} sm={3}>
                URL
            </Grid>

            <Grid item xs={12} sm={9}>
                <TextField
                    fullWidth
                    id="settings.url"
                    name="settings.url"
                    value={values.settings.url ?? ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!touched.settings?.url && !!errors.settings?.url}
                    helperText={touched.settings?.url ? errors.settings?.url : undefined}
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                Method
            </Grid>

            <Grid item xs={12} sm={9}>
                <TextField
                    fullWidth
                    id="settings.method"
                    name="settings.method"
                    value={values.settings.method}
                    onChange={(e) => setFieldValue('settings.method', e.target.value)}
                    onBlur={handleBlur}
                    select
                    error={!!touched.settings?.method && !!errors.settings?.method}
                    helperText={touched.settings?.method ? errors.settings?.method : undefined}
                >
                    <MenuItem value="POST">POST</MenuItem>
                    <MenuItem value="GET">GET</MenuItem>
                </TextField>
            </Grid>

            <Grid item xs={12} sm={3}>
                Secret
            </Grid>

            <Grid item xs={12} sm={9}>
                <TextField
                    fullWidth
                    id="settings.secret"
                    name="settings.secret"
                    value={values.settings.secret}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!touched.settings?.secret && !!errors.settings?.secret}
                    helperText={touched.settings?.secret ? errors.settings?.secret : undefined}
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                Services
            </Grid>

            <Grid item xs={12} sm={9}>
                <Autocomplete
                    multiple
                    options={services.map(({ id }) => id)}
                    renderInput={(props) => <TextField {...props} name="service_ids" placeholder={isLoading ? 'Loading' : undefined} />}
                    fullWidth
                    value={isLoading ? [] : values.service_ids ?? []}
                    getOptionLabel={(option) => getNameById(option) ?? ''}
                    onChange={onServiceChange}
                    loading={isLoading}
                    disabled={isLoading}
                />
            </Grid>
        </Grid>
    );
};

export default WebhookSettingsForm;
