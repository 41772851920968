import { FC, useCallback, useContext, useMemo } from 'react';
import { SearchOptionProps } from '../models';
import { Avatar, Box, MenuItem, Typography } from '@mui/material';
import { stringToColor } from '../../../store/constant';
import { format } from 'date-fns';
import appointmentStatuses from '../../../views/calendar/constants/appointment-statuses';
import { AppointmentStatuses } from '../../../models/IAppointment';
import { AbilityContext } from '../../../utils/roles/Can';
import OptionTextWrapper from './OptionTextWrapper';

const AppointmentOption: FC<SearchOptionProps> = ({ option, onClick, isMobile }) => {
    const ability = useContext(AbilityContext);
    const icon = useMemo(() => appointmentStatuses.find((status) => status.value === option.details.status)?.icon ?? null, [
        option.details.status
    ]);

    const canSeeAppointmentDetails = useMemo(() => ability.can('view', 'appointment'), [ability]);

    const getStatusColor = useCallback((s: string) => {
        switch (s) {
            case AppointmentStatuses.Completed:
                return '#00a651';
            case AppointmentStatuses.Canceled:
                return '#f37a7c';
            case AppointmentStatuses.Pending:
                return '#fcaf07';
            default:
                return 'primary.main';
        }
    }, []);

    return (
        <MenuItem
            disableRipple={!canSeeAppointmentDetails}
            disableTouchRipple={!canSeeAppointmentDetails}
            sx={{
                paddingTop: 1,
                paddingBottom: 1,
                '&:hover': { background: canSeeAppointmentDetails ? 'rgba(237, 231, 246, 0.70)' : 'transparent' },
                whiteSpace: 'normal',
                cursor: canSeeAppointmentDetails ? 'pointer' : 'default'
            }}
            onClick={canSeeAppointmentDetails ? () => onClick(option.id) : undefined}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'grid',
                    gridGap: '8px',
                    gridTemplateColumns: `28px ${isMobile ? 90 : 140}px 1fr 20px 62px`,
                    alignItems: 'center'
                }}
            >
                <Avatar sx={{ width: '28px', height: '28px', bgcolor: stringToColor(option.label), color: '#fff' }} src={option.image}>
                    <Typography>{option.label.charAt(0)}</Typography>
                </Avatar>
                <OptionTextWrapper>{option.label}</OptionTextWrapper>
                <OptionTextWrapper>{option.details.customerName}</OptionTextWrapper>
                <Box
                    sx={{
                        color: getStatusColor(option.details.status ?? ''),
                        display: 'inline-flex',
                        alignItems: 'center'
                    }}
                >
                    {icon}
                </Box>
                <OptionTextWrapper>{option.details.date ? format(new Date(option.details.date), 'MM/dd/yy') : '-'}</OptionTextWrapper>
            </Box>
        </MenuItem>
    );
};

export default AppointmentOption;
