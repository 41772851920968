import { Box, Stack } from '@mui/material';
import SectionHeading from '../../SectionHeading';
import appointmentAPI from '../../../../../../../services/AppointmentService';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux';
import { skipToken } from '@reduxjs/toolkit/query';
import AppointmentProductForm, { AppointmentProductFormType } from './AppointmentProductForm';
import { useCallback, useMemo, useState } from 'react';
import { openConfirmPopup } from '../../../../../../../store/confirmPopupSlice';
import { IAppointmentProduct } from '../../../../../../../models/IProduct';
import AppointmentProductsTable from './AppointmentProductsTable';
import AddIconButton from '../../../../../../../ui-component/AddIconButton';
import ConditionalDialog from '../../../../../../../ui-component/conditional-dialog/ConditionalDialog';
import useShowSnackbar from '../../../../../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../../../../../store/snackbarReducer';
import { startSubmitting, stopSubmitting } from '../../../../../../../store/slices/SubmittingSlice';
import useProductsOptions from '../../../../../../../hooks/options/useProductsOptions';

const AppointmentProducts = () => {
    const [creating, setCreating] = useState(false);
    const [editing, setEditing] = useState<IAppointmentProduct | null>(null);
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const { selectedEvent, isForeignAppointment } = useAppSelector((state) => state.calendar);
    const selectedEventId = selectedEvent?.id;

    const { data, isLoading } = appointmentAPI.useListAppointmentProductsQuery(
        selectedEventId ? { appointmentId: selectedEventId } : skipToken
    );

    const products = useMemo<IAppointmentProduct[]>(() => data ?? [], [data]);
    const { options } = useProductsOptions(products.map(({ id }) => id));

    const [saveAppointmentProduct, { isLoading: isSaving }] = appointmentAPI.useCreateAppointmentProductMutation();
    const [updateAppointmentProduct, { isLoading: isUpdating }] = appointmentAPI.useUpdateAppointmentProductMutation();
    const [deleteAppointmentProduct] = appointmentAPI.useDeleteAppointmentProductMutation();

    const showMainContent = useMemo(() => !creating && !editing, [creating, editing]);
    const canAddProduct = useMemo(() => showMainContent && !!options.length && !isForeignAppointment, [
        showMainContent,
        options.length,
        isForeignAppointment
    ]);

    const handleError = useCallback(
        (err: any) => {
            showSnackbar({
                alertSeverity: SnackBarTypes.Error,
                message: err.data ?? err.message ?? JSON.stringify(err)
            });
        },
        [showSnackbar]
    );

    const handleCreateProduct = useCallback(
        (formData: AppointmentProductFormType) => {
            if (selectedEventId) {
                const { productId, ...rest } = formData;
                saveAppointmentProduct({
                    appointmentId: selectedEventId,
                    productId,
                    payload: rest
                })
                    .unwrap()
                    .then((result) => {
                        setCreating(false);
                        dispatch(
                            appointmentAPI.util?.updateQueryData(
                                'listAppointmentProducts',
                                {
                                    appointmentId: selectedEventId
                                },
                                (prev) => (!prev.some((i) => i.id === result.id) ? [...prev, result] : prev)
                            )
                        );
                    })
                    .catch((err) => {
                        handleError(err);
                    });
            }
        },
        [selectedEventId, saveAppointmentProduct, dispatch, handleError]
    );

    const onUpdateProduct = useCallback((product: IAppointmentProduct) => {
        setEditing(product);
    }, []);

    const handleUpdateProduct = useCallback(
        (formData: AppointmentProductFormType) => {
            if (selectedEventId) {
                const { productId, ...rest } = formData;
                updateAppointmentProduct({
                    appointmentId: selectedEventId,
                    productId,
                    payload: rest
                })
                    .unwrap()
                    .then((result) => {
                        setEditing(null);
                        dispatch(
                            appointmentAPI.util?.updateQueryData(
                                'listAppointmentProducts',
                                {
                                    appointmentId: selectedEventId
                                },
                                (prev) => prev.map((i) => (i.id === result.id ? result : i))
                            )
                        );
                    })
                    .catch((err) => {
                        handleError(err);
                    });
            }
        },
        [selectedEventId, updateAppointmentProduct, dispatch, handleError]
    );

    const handleDeleteProduct = useCallback(
        (productId: number) => {
            if (selectedEventId) {
                dispatch(startSubmitting());
                deleteAppointmentProduct({ appointmentId: selectedEventId, productId })
                    .unwrap()
                    .then(() => {
                        dispatch(
                            appointmentAPI.util?.updateQueryData(
                                'listAppointmentProducts',
                                {
                                    appointmentId: selectedEventId
                                },
                                (prev) => prev.filter((i) => i.id !== productId)
                            )
                        );
                    })
                    .catch((err) => handleError(err))
                    .finally(() => {
                        dispatch(stopSubmitting());
                    });
            }
        },
        [selectedEventId, dispatch, deleteAppointmentProduct, handleError]
    );

    const onDeleteProduct = useCallback(
        (productId: number) => {
            dispatch(
                openConfirmPopup({
                    title: 'Delete Product',
                    text: 'Are you sure you want to remove this product from appointment?',
                    confirmText: 'Delete',
                    onConfirm: () => handleDeleteProduct(productId)
                })
            );
        },
        [dispatch, handleDeleteProduct]
    );

    return (
        <Stack display="flex" height="100%" spacing={2}>
            <Stack direction="row" px={2} alignItems="center" justifyContent="space-between">
                <SectionHeading>Products</SectionHeading>

                {showMainContent && (
                    <AddIconButton
                        onClick={() => setCreating(true)}
                        disabled={!canAddProduct}
                        sx={{ alignSelf: 'flex-start', flexGrow: 0, flexShrink: 0 }}
                    />
                )}
            </Stack>
            {showMainContent ? (
                <AppointmentProductsTable products={products} isLoading={isLoading} onDelete={onDeleteProduct} onUpdate={onUpdateProduct} />
            ) : null}

            <Box px={2}>
                <ConditionalDialog
                    title="Add Product"
                    okButtonLabel="Save"
                    formId="appointment-product-form"
                    open={creating}
                    onCancel={() => setCreating(false)}
                    okBtnDisabled={isSaving}
                    cancelBtnDisabled={isSaving}
                >
                    <AppointmentProductForm
                        onSubmit={handleCreateProduct}
                        isBusy={isSaving}
                        excludedValues={products.map(({ id }) => id)}
                    />
                </ConditionalDialog>

                <ConditionalDialog
                    title="Edit Product"
                    okButtonLabel="Save"
                    formId="appointment-product-form"
                    open={!!editing}
                    onCancel={() => setEditing(null)}
                    okBtnDisabled={isUpdating}
                    cancelBtnDisabled={isUpdating}
                >
                    <AppointmentProductForm
                        product={
                            editing
                                ? {
                                      productId: editing.id,
                                      amount: editing.pivot.amount,
                                      price: editing.pivot.price
                                  }
                                : undefined
                        }
                        productData={editing}
                        onSubmit={handleUpdateProduct}
                        isBusy={isUpdating}
                        excludedValues={products.filter(({ id }) => id !== editing?.id).map(({ id }) => id)}
                    />
                </ConditionalDialog>
            </Box>
        </Stack>
    );
};

export default AppointmentProducts;
