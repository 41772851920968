import companyAPI from '../../../services/CompanyService';
import { FC, useCallback, useMemo, useState } from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';
import { useAppDispatch } from '../../../hooks/redux';
import useWebhookDefaults from './use-webhook-defaults';
import WebhookSettingsForm from './WebhookSettingsForm';
import { startSubmitting, stopSubmitting } from '../../../store/slices/SubmittingSlice';
import CBModal from '../../../ui-component/cb-modal/CBModal';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import { Theme, useMediaQuery } from '@material-ui/core';
import { IWebhookItem, IWebhookSettingsInput } from '../types';

const WebhookTableRow: FC<{ companyId: number; row: IWebhookItem }> = ({ companyId, row }) => {
    const dispatch = useAppDispatch();
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const [edit, setEdit] = useState(false);
    const { defaultValue, triggerData } = useWebhookDefaults(companyId, row);
    const [deleteHook, { isLoading: isDeleting }] = companyAPI.useDeleteCompanyWebhookMutation();
    const [updateHook, { isLoading: isUpdating }] = companyAPI.useUpdateCompanyWebhookMutation();

    const { showSnackbar } = useShowSnackbar();

    const onUpdate = useCallback(
        (formData: IWebhookSettingsInput) => {
            dispatch(startSubmitting());
            updateHook({ companyId, hookId: row.id, data: formData })
                .unwrap()
                .then(() => {
                    setEdit(false);
                    showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'Webhook successfully updated' });
                })
                .catch((err) => {
                    showSnackbar({
                        alertSeverity: SnackBarTypes.Error,
                        message: err.data ?? err.message ?? JSON.stringify(err)
                    });
                })
                .finally(() => {
                    dispatch(stopSubmitting());
                });
        },
        [companyId, dispatch, row.id, showSnackbar, updateHook]
    );

    const onDelete = useCallback(() => {
        dispatch(
            openConfirmPopup({
                title: 'Deleting Webhook',
                text: 'Are you sure you want to delete this web hook?',
                confirmText: 'Confirm',
                onConfirm: () => {
                    deleteHook({ companyId, hookId: row.id })
                        .unwrap()
                        .then(() => {
                            showSnackbar({
                                alertSeverity: SnackBarTypes.Success,
                                message: 'Webhook successfully deleted'
                            });
                        })
                        .catch((err) => {
                            showSnackbar({
                                alertSeverity: SnackBarTypes.Error,
                                message: err.data ?? err.message ?? JSON.stringify(err)
                            });
                        });
                }
            })
        );
    }, [companyId, deleteHook, dispatch, row.id, showSnackbar]);

    const actionsDisabled = useMemo(() => isDeleting || isUpdating, [isDeleting, isUpdating]);

    return (
        <>
            <TableRow>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.is_enabled ? <CheckCircleOutlineIcon color="success" /> : <HighlightOffIcon color="error" />}</TableCell>
                <TableCell>{row.settings.url}</TableCell>
                <TableCell sx={{ textAlign: 'right', py: 0 }}>
                    {!!triggerData && (
                        <IconButton color="primary" size="small" disabled={actionsDisabled} onClick={() => setEdit(true)}>
                            <EditIcon />
                        </IconButton>
                    )}
                    <IconButton color="error" size="small" onClick={onDelete} disabled={actionsDisabled}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            {!!triggerData && (
                <CBModal
                    open={edit}
                    onClose={() => setEdit(false)}
                    title="Edit Web Hook"
                    okButtonText="Save"
                    okButtonFormId="webhook-form"
                    okButtonDisabled={isUpdating}
                    maxWidth="sm"
                    fullWidth
                    fullScreen={matchSm}
                >
                    <WebhookSettingsForm defaults={defaultValue} triggersData={triggerData} onSave={onUpdate} />
                </CBModal>
            )}
        </>
    );
};

export default WebhookTableRow;
