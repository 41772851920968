import { ErrorBoundary } from 'react-error-boundary';
import { FC, PropsWithChildren } from 'react';
import ErrorFallback from './ErrorFallback';

type ErrorCatcherPropsType = { wrappedComponentName?: string };

const connectionErrorText =
    'This probably happened because of unreliable internet connection. Please try again later or in an environment with a more stable network connection.';

const ErrorCatcher: FC<PropsWithChildren<ErrorCatcherPropsType>> = ({ children, wrappedComponentName }) => (
    <ErrorBoundary
        onReset={() => window.location.reload()}
        fallbackRender={({ error, resetErrorBoundary }) => (
            <ErrorFallback title="The application could not load." text={connectionErrorText} error={error} onReset={resetErrorBoundary} />
        )}
    >
        {children}
    </ErrorBoundary>
);

export default ErrorCatcher;
