import { useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { useTheme, Theme } from '@material-ui/core/styles';
import { AppBar, CssBaseline, Toolbar, useMediaQuery, Alert, Link, Box } from '@material-ui/core';

// third-party
import { hotjar } from 'react-hotjar';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import { SET_MENU } from 'store/actions';
import { DefaultRootStateProps } from 'types';

// assets
import { IconChevronRight } from '@tabler/icons';
import useAuth from '../../hooks/useAuth';

// casl
import { AbilityContext } from '../../utils/roles/Can';
import { Ability, AbilityBuilder } from '@casl/ability';
import { baseUserActions } from '../../utils/roles/ability';
import { IUser, UserPermission } from '../../models/IUser';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import config from '../../config';
import EntityDialogs from '../../ui-component/entity-dialogs/EntityDialogs';
import useNavigationMode from '../use-navigation-mode';
import { setHeaderHeight } from '../../store/slices/layoutSlice';
import CBBottomNav from '../CBBottomNav';

// style constant
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex'
    },
    appBar: {
        backgroundColor: theme.palette.background.default
    },
    appBarWidth: {
        transition: theme.transitions.create('width'),
        backgroundColor: theme.palette.background.default
    },
    content: {
        ...theme.typography.mainContent,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        '@media(max-width:768px)': {
            padding: '0 0 56px 0',
            marginRight: 0
        },

        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
            marginTop: '60px'
        },
        '& > div': {
            border: 'none'
        },
        paddingBottom: 0
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    }
}));

const updateAbility = (ability: Ability, user: IUser) => {
    const {
        employee: {
            role: { permissions }
        }
    } = user;
    const { can, rules } = new AbilityBuilder(Ability);

    permissions.forEach(({ action, subject }: UserPermission) => {
        if (action === '*') {
            baseUserActions.forEach((baseAction) => {
                can(baseAction, subject);
            });
        } else {
            can(action, subject);
        }
    });

    ability.update(rules);
};

declare global {
    interface Window {
        // $crisp: any;
        // CRISP_WEBSITE_ID: string;
        LiveAgent: any;
    }
}

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const navMode = useNavigationMode();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyles();
    const { user } = useAuth();
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const { showSupportWidget } = useAppSelector((state) => state.layout);

    useEffect(() => {
        if (location.pathname === '/') {
            navigate(config.defaultPath, { replace: true });
        }
    }, [location, navigate]);

    // Handle left drawer
    const leftDrawerOpened = useSelector((state: DefaultRootStateProps) => state.customization.opened);
    const handleLeftDrawerToggle = useCallback(() => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    }, [dispatch, leftDrawerOpened]);

    const ability = useContext(AbilityContext);

    const toolbarRef = useRef<HTMLDivElement | null>(null);
    const element = toolbarRef?.current;

    useEffect(() => {
        const observer = new ResizeObserver((data) => {
            const height = data[0]?.contentRect.height ?? 0;
            dispatch(setHeaderHeight(height));
        });

        if (element) {
            observer.observe(element);
        }

        return () => {
            // Cleanup the observer by unobserving all elements
            dispatch(setHeaderHeight(0));
            observer.disconnect();
        };
    }, [dispatch, element]);

    const roleName = user?.employee.role.name;

    useEffect(() => {
        if (user) {
            updateAbility(ability, user);
        }
    }, [user, roleName, ability]);

    useEffect(() => {
        if (process.env.NODE_ENV !== 'production') return;

        hotjar.initialize(3409396, 6);

        // eslint-disable-next-line func-names
        (function () {
            const s = document.createElement('script');
            s.src = 'https://chilled.ladesk.com/scripts/track.js';
            s.id = 'la_x2s6df8d';
            s.type = 'text/javascript';
            s.async = true;
            s.onload = () => {
                window.LiveAgent.clearAllUserDetails();
                window.LiveAgent.setUserDetails(user?.email, user?.firstname, user?.lastname, user?.phone);
                window.LiveAgent.createButton('nrj5j816', document.getElementById('LiveAgent-chatButton'));
            };
            document.getElementsByTagName('head')[0].appendChild(s);
        })();
    }, [user]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            {/* header */}
            <AppBar
                id="app-header"
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                className={leftDrawerOpened ? classes.appBarWidth : classes.appBar}
                ref={toolbarRef}
            >
                <Toolbar sx={{ pl: matchSm ? '24px !important' : undefined, pb: matchSm ? '8px !important' : undefined }}>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} matchSm={matchSm} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            {navMode === 'sidebar' ? <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} /> : null}

            {/* main content */}
            <main className={classes.content}>
                {user?.is_impersonated && (
                    <Alert severity="error" sx={{ marginBottom: theme.spacing(1), borderRadius: '8px' }}>
                        You&apos;re in the impersonate mode, <Link href="/select-organization">select another user.</Link>
                    </Alert>
                )}
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
                <CBBottomNav />
            </main>
            <EntityDialogs />
            <Box sx={{ display: showSupportWidget ? 'inherit' : 'none' }}>
                <div id="LiveAgent-chatButton" />
            </Box>
        </div>
    );
};

export default MainLayout;
