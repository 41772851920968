import { useCallback } from 'react';
import { IService } from '../models/IService';
import { BookingWidgetAnswer } from '../views/scheduling-widget/widget-wizard/types';

const useServicesSurvey = () => {
    const serviceHasSurvey = useCallback((service: IService) => service.widget_questions && service.widget_questions?.length > 0, []);

    const convertServiceToRawAnswer = useCallback(
        (service: IService): BookingWidgetAnswer => ({
            service_id: service.id,
            answers: service.widget_questions
                ? service.widget_questions?.map((q) => ({
                      title: q.title,
                      answer: ''
                  }))
                : []
        }),
        []
    );

    const servicesHasMismatchWithAnswers = useCallback(
        (services: IService[], answers?: BookingWidgetAnswer[] | null): boolean => {
            const selectedServicesWithSurveyIds = services.filter(serviceHasSurvey).map(({ id }) => id);
            const selectedAnswersIds: number[] = answers?.map(({ service_id }) => service_id) ?? [];

            const hasMismatch =
                selectedServicesWithSurveyIds.some((id) => !selectedAnswersIds.includes(id)) ||
                selectedAnswersIds.some((id) => !selectedServicesWithSurveyIds.includes(id));

            return !answers || hasMismatch;
        },
        [serviceHasSurvey]
    );

    const convertServicesToSurveyAnswers = useCallback(
        (services: IService[]): BookingWidgetAnswer[] => {
            const servicesWithSurvey = services.filter(serviceHasSurvey);
            return servicesWithSurvey.map(convertServiceToRawAnswer);
        },
        [convertServiceToRawAnswer, serviceHasSurvey]
    );

    return { convertServicesToSurveyAnswers, servicesHasMismatchWithAnswers };
};

export default useServicesSurvey;
