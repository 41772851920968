import { FC } from 'react';
import { Grid, TextField } from '@mui/material';
import * as Yup from 'yup';
import useExtendedFormik from '../../../hooks/useExtendedFormik';

const ApiKeyForm: FC<{ defaults: { name: string }; onSubmit: (data: { name: string }) => void }> = ({ defaults, onSubmit }) => {
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useExtendedFormik<{ name: string }>({
        initialValues: defaults,
        onSubmit,
        validationSchema: Yup.object().shape({
            name: Yup.string().trim().required().label('Name')
        })
    });

    return (
        <Grid container component="form" spacing={2} id="api-key-form" onSubmit={handleSubmit} noValidate autoComplete="off">
            <Grid item xs={12} sm={3}>
                Name
            </Grid>
            <Grid item xs={12} sm={9}>
                <TextField
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={!!errors.name && touched.name}
                    helperText={touched.name ? errors.name : undefined}
                />
            </Grid>
        </Grid>
    );
};

export default ApiKeyForm;
