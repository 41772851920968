import { Box, Stack } from '@mui/material';
import productsAPI from '../services/ProductsService';
import { FC, useCallback, useMemo } from 'react';
import CommissionSelect from './service-select/CommissionSelect';
import { EmployeeCommissionType, EmployeeProductType } from '../models/IEmployee';
import { useMediaQuery } from '@material-ui/core';
import { FormikErrors } from 'formik';
import { IProduct } from '../models/IProduct';

const MAX_ROWS = 5;
type EmployeeProductsSelectProps = {
    value: EmployeeProductType[];
    onChange: (v: EmployeeProductType[]) => void;
    errors?: string | string[] | FormikErrors<EmployeeProductType[]>;
};

const EmployeeProductsSelect: FC<EmployeeProductsSelectProps> = ({ value = [], onChange, errors }) => {
    const matchXs = useMediaQuery('(max-width:420px)');
    const { data: listData } = productsAPI.useFetchProductsQuery({});
    const products = useMemo(() => listData?.data ?? [], [listData]);

    const getPivotById = useCallback((id: number) => value.find((i) => i.id === id), [value]);

    const updatePivot = useCallback(
        (id: number, data: Partial<EmployeeProductType>) => {
            const newValue = value.map((v) => (v.id === id ? { ...v, ...data } : v));
            return onChange(newValue);
        },
        [onChange, value]
    );

    const addPivot = useCallback(
        (id: number) => {
            onChange([
                ...value,
                {
                    id,
                    pivot: { commission_type: EmployeeCommissionType.Fixed, commission_amount: 0 }
                }
            ]);
        },
        [onChange, value]
    );

    const removePivot = useCallback(
        (deleteId: number) => {
            const newValue = [...value].filter(({ id }) => id !== deleteId);
            onChange(newValue);
        },
        [onChange, value]
    );

    const toggleOverride = useCallback(
        (v: boolean, product: IProduct) => {
            if (v) {
                addPivot(product.id);
            } else {
                removePivot(product.id);
            }
        },
        [addPivot, removePivot]
    );

    const maxHeight = useMemo(() => {
        const rowsHeight = MAX_ROWS * 45;
        const gaps = 16 * (MAX_ROWS - 1);

        return matchXs ? rowsHeight + gaps + 8 : 'auto';
    }, [matchXs]);

    const getRowError = useCallback(
        (id: number) => {
            const index = value.map((v) => v.id).indexOf(id);
            if (index >= 0 && errors && Array.isArray(errors)) {
                const err = errors[index];
                return !err || typeof err === 'string' ? err : Object.values(err.pivot ?? {}).join('; ');
            }

            return null;
        },
        [errors, value]
    );

    return (
        <Stack spacing={2} sx={{ maxHeight: `${maxHeight}px`, overflow: 'auto', pt: 1 }}>
            {products.map((product) => {
                const override = getPivotById(product.id)?.pivot;
                return (
                    <Stack
                        spacing={2}
                        key={product.id}
                        direction={matchXs ? 'column' : 'row'}
                        alignItems={matchXs ? undefined : 'center'}
                        justifyContent="space-beetween"
                        sx={{ width: '100%' }}
                    >
                        <Box sx={{ flexGrow: 1 }}>{product.name}</Box>
                        <CommissionSelect
                            hasOverride={!!override}
                            type={override?.commission_type ?? null}
                            amount={override?.commission_amount ?? null}
                            onToggle={(v) => toggleOverride(v, product)}
                            onTypeChange={(v) =>
                                updatePivot(product.id, {
                                    pivot: {
                                        commission_amount: override?.commission_amount ?? null,
                                        commission_type: v
                                    }
                                })
                            }
                            onAmountChange={(v) =>
                                updatePivot(product.id, {
                                    pivot: {
                                        commission_type: override?.commission_type ?? null,
                                        commission_amount: v
                                    }
                                })
                            }
                            error={getRowError(product.id) ?? null}
                        />
                    </Stack>
                );
            })}
        </Stack>
    );
};

export default EmployeeProductsSelect;
