import { useEffect, useRef } from 'react';

// project imports
import { IAppointment } from '../../../models/IAppointment';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { clearOuterAppointment } from '../../../store/slices/outerAppointmentSlice';
import appointmentAPI from '../../../services/AppointmentService';
import { skipToken } from '@reduxjs/toolkit/query/react';
import CBModal from '../../../ui-component/cb-modal/CBModal';
import { useTheme } from '@material-ui/core/styles';
import AppointmentDetails from './appointment-details';
import { setSelectedEvent as setStoreEvent } from '../../../store/slices/calendarSlice';
import DeleteButton from './components/DeleteButton';
import useGetColorByStatus from '../../../hooks/useGetColorByStatus';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';
import { ModalProps } from '@mui/material';

export interface AppointmentCardProps {
    isOpen: boolean;
    cardEvent: IAppointment | string | undefined;
    onCancel: () => void;
    matchSm: boolean;
    container?: ModalProps['container'];
}

const AppointmentCard = ({ isOpen, cardEvent, onCancel, matchSm, container }: AppointmentCardProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const {
        appointmentDetailsFormId,
        appointmentDetailsSaveButtonDisabled,
        selectedEvent: storeEvent,
        isForeignAppointment,
        shouldSubmitFormOnTabChange
    } = useAppSelector((state) => state.calendar);
    const { getColorByStatus } = useGetColorByStatus();
    // event from calendar -> get his id -> fetch full appointment info
    const eventId = typeof cardEvent === 'string' && !isNaN(Number(cardEvent)) ? Number(cardEvent) : undefined;
    const { data: appointment, isLoading, isFetching } = appointmentAPI.useGetAppointmentQuery(eventId ?? skipToken, {
        refetchOnMountOrArgChange: true
    });

    const modalContentRef = useRef<null | HTMLDivElement>(null);
    const submitButtonRef = useRef<null | HTMLButtonElement>(null);

    useEffect(() => {
        if (appointment && (!storeEvent || storeEvent.id !== eventId)) {
            dispatch(setStoreEvent(appointment));
        }
    }, [appointment, dispatch, eventId, storeEvent]);

    const closeEventCard = () => {
        onCancel();
        dispatch(clearOuterAppointment());
        dispatch(setStoreEvent(null));
    };

    const onCloseEventCard = () => {
        if (shouldSubmitFormOnTabChange) {
            dispatch(
                openConfirmPopup({
                    text: 'Discard unsaved changes?',
                    confirmText: 'Discard',
                    onConfirm: () => closeEventCard()
                })
            );
        } else {
            closeEventCard();
        }
    };

    const appointmentLoading = isLoading || isFetching;

    return (
        <CBModal
            headerColor={getColorByStatus(storeEvent?.status)}
            open={isOpen}
            onClose={onCloseEventCard}
            title="Appointment Details"
            maxWidth="md"
            fullWidth
            fullScreen={matchSm}
            contentRef={modalContentRef}
            okButtonText="save"
            okButtonFormId={appointmentDetailsFormId}
            okButtonDisabled={
                appointmentDetailsSaveButtonDisabled || isForeignAppointment || !shouldSubmitFormOnTabChange || appointmentLoading
            }
            styleOverrides={{
                '& .MuiDialogActions-root': { borderTop: `1px solid ${theme.palette.grey[300]}` },
                '& .MuiDialogContent-root': { p: '0 !important', height: '900px' }
            }}
            specialContent={appointmentLoading ? null : <DeleteButton onSuccess={closeEventCard} disabled={isForeignAppointment} />}
            submitButtonRef={submitButtonRef}
            container={container}
        >
            <AppointmentDetails submitButtonRef={submitButtonRef} isLoading={appointmentLoading} />
        </CBModal>
    );
};

export default AppointmentCard;
