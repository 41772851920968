import { Box, Stack, Typography } from '@mui/material';
import CustomPhoneInput from '../form/CustomPhoneInput';
import { useFormik } from 'formik';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';
import { SnackBarTypes } from '../../store/snackbarReducer';
import * as Yup from 'yup';
import { isValidPhone } from '../../utils/phone-helpers';
import { useAppDispatch } from '../../hooks/redux';
import appointmentAPI from '../../services/AppointmentService';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { FC, useCallback } from 'react';
import ConditionalDialog from '../conditional-dialog/ConditionalDialog';

const SelfCheckoutForm: FC<{
    phone?: string;
    appointmentId: number;
    open: boolean;
    onClose: () => void;
}> = ({ phone = '', appointmentId, open, onClose }) => {
    const dispatch = useAppDispatch();
    const [send] = appointmentAPI.useSendSelfCheckoutInviteMutation();
    const { showSnackbar } = useShowSnackbar();

    const { handleSubmit, setFieldValue, values, errors, resetForm } = useFormik<{ phone: string }>({
        initialValues: { phone },
        enableReinitialize: true,
        validateOnChange: true,
        onSubmit: (data) => {
            dispatch(startSubmitting());
            send({ phone: data.phone, appointment_id: appointmentId })
                .unwrap()
                .then(() => {
                    showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'Self Checkout link sent' });
                })
                .catch((err) => {
                    showSnackbar({
                        alertSeverity: SnackBarTypes.Error,
                        message: err.message ?? err.data ?? JSON.stringify(err)
                    });
                })
                .finally(() => {
                    handleClose();
                    dispatch(stopSubmitting());
                });
        },
        validationSchema: Yup.object().shape({
            phone: Yup.string()
                .trim()
                .required('Phone number is required')
                .test({ name: 'phone', message: 'Must be a valid phone number', test: (v) => isValidPhone(v ?? '') })
        })
    });

    const handleClose = useCallback(() => {
        onClose();
        resetForm();
    }, [resetForm, onClose]);

    return (
        <ConditionalDialog open={open} onCancel={handleClose} title="Self Checkout" okButtonLabel="Send Request" formId="selfCheckoutForm">
            <Box component="form" id="selfCheckoutForm" onSubmit={handleSubmit}>
                <Stack spacing={1} sx={{ textAlign: 'center', mb: 2.25 }}>
                    <Typography sx={{ fontSize: '18px', color: 'rgba(0,0,0,0.84)' }}>Confirm customer&apos;s phone number</Typography>
                    <Typography fontSize="1rem">
                        A payment request will be sent to the following
                        <br />
                        phone number:
                    </Typography>
                </Stack>
                <CustomPhoneInput
                    label="Phone"
                    value={values.phone}
                    onChange={(v) => setFieldValue('phone', v)}
                    fullWidth
                    error={!!errors.phone}
                    helperText={errors.phone}
                />
            </Box>
        </ConditionalDialog>
    );
};

export default SelfCheckoutForm;
