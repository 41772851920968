import * as Yup from 'yup';

const ShiftScheme = Yup.object().shape({
    opened: Yup.boolean().required(),
    startTime: Yup.string()
        .required('Start time is required')
        .test({
            name: 'startTime',
            test: (v, ctx) => {
                const endTime = new Date(`2000-10-10T${ctx.parent.endTime}`).valueOf();
                const startTime = new Date(`2000-10-10T${v}`).valueOf();
                return endTime > startTime;
            },
            message: 'Must be earlier then End time!'
        }),
    endTime: Yup.string().required('End time is required'),
    shifts: Yup.array().of(Yup.string().trim()).min(1, 'At least one date is required')
});

export default ShiftScheme;
