import waiverAPI from '../../../services/WaiverService';
import { useMemo } from 'react';
import { CircularProgress, FormControl, FormHelperText, Grid, Typography } from '@material-ui/core';
import { MenuItem, Select, useMediaQuery } from '@mui/material';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';

interface WaiverSelectorProps {
    fieldName: string;
    setFieldValue: (fieldName: string, value: any) => void;
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    value?: number | null;
}

const WaiverSelector = ({ fieldName, setFieldValue, errors, touched, value }: WaiverSelectorProps) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const { data, isLoading } = waiverAPI.useFetchAllWaiversQuery({
        per_page: -1
    });

    const waivers = useMemo(() => (!isLoading ? data?.data || [] : []), [data, isLoading]);

    return (
        <>
            <Grid item xs={9} sm={3} lg={4} />
            <Grid item xs={3} sm={9} lg={6} textAlign={isMobile ? 'right' : 'left'}>
                {isLoading && <CircularProgress />}
                {waivers.length ? (
                    <FormControl fullWidth error={Boolean(touched.waivers && errors.waivers)}>
                        <Select
                            id="forms"
                            value={value}
                            name="payment_type"
                            onChange={(event) => {
                                setFieldValue(fieldName, event.target.value);
                            }}
                        >
                            {waivers.map((waiver, index) => (
                                <MenuItem key={`waiver-${index}`} value={waiver.id}>
                                    {waiver.title}
                                </MenuItem>
                            ))}
                        </Select>
                        {touched.waivers && errors.waivers && (
                            <FormHelperText error id="errors-waivers">
                                {errors.waivers}
                            </FormHelperText>
                        )}
                    </FormControl>
                ) : (
                    <Typography>There is no forms yet.</Typography>
                )}
            </Grid>
        </>
    );
};

export default WaiverSelector;
