import { useCallback, useReducer } from 'react';
import { ArrangementOrder } from '../types';

type PaginationAndSortingState = {
    search: string;
    page: number;
    per_page: number;
    order: ArrangementOrder;
    sort?: string;
};

const usePaginationAndSorting = (defaults?: Partial<PaginationAndSortingState>) => {
    const [state, dispatch] = useReducer(
        (prev: PaginationAndSortingState, payload: Partial<PaginationAndSortingState>) => ({ ...prev, ...payload }),
        {
            search: '',
            page: 1,
            per_page: 10,
            order: 'asc',
            sort: undefined,
            ...defaults
        }
    );

    const setPage = useCallback((page: number) => {
        dispatch({ page });
    }, []);

    const setSearch = useCallback((search: string) => {
        dispatch({ search, page: 1 });
    }, []);

    const setPageSize = useCallback((per_page?: number) => {
        if (per_page) {
            dispatch({ per_page, page: 1 });
        }
    }, []);

    const handleSorting = useCallback(
        (newSort: string) => {
            const { sort, order } = state;
            const isAsc = sort === newSort && order === 'asc';
            dispatch({ order: isAsc ? 'desc' : 'asc', sort: newSort });
        },
        [state]
    );

    return { pageState: state, setSearch, setPage, setPageSize, handleSorting };
};

export default usePaginationAndSorting;
