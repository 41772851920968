import React, { FC, useCallback, useState } from 'react';
import { ButtonBase, Menu, MenuItem } from '@mui/material';
import { EmployeeCommissionType } from '../../models/IEmployee';
import OverrideInput from './OverrideInput';
import { Divider } from '@material-ui/core';

type CommissionSelectPropsType = {
    hasOverride: boolean;
    type: EmployeeCommissionType | null;
    amount: number | null;
    onToggle?: (v: boolean) => void;
    onTypeChange: (v: EmployeeCommissionType | null) => void;
    onAmountChange: (v: number | null) => void;
    error?: string | null;
    onBlur?: () => void;
    hideLabel?: boolean;
};

const CommissionSelect: FC<CommissionSelectPropsType> = ({
    hasOverride,
    onToggle,
    type,
    onTypeChange,
    amount,
    onAmountChange,
    error,
    onBlur,
    hideLabel
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const openTypeSelect = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeTypeSelect = () => {
        setAnchorEl(null);
    };

    const handleTypeChange = useCallback(
        (value: EmployeeCommissionType | null) => {
            onTypeChange(value === 'fixed' || value === 'percent' ? value : null);
            setAnchorEl(null);
            if (value === 'percent' && amount && amount > 100) {
                onAmountChange(100);
            }
        },
        [amount, onAmountChange, onTypeChange]
    );

    return (
        <OverrideInput
            hasOverride={hasOverride}
            onOverrideChange={onToggle}
            overrideValue={amount}
            onChange={(v) => onAmountChange(v.floatValue ?? null)}
            fieldProps={{
                InputProps: {
                    sx: { pl: 1 },
                    startAdornment: (
                        <>
                            <ButtonBase sx={{ p: 1 }} onClick={openTypeSelect}>
                                {type === 'fixed' ? '$' : '%'}
                            </ButtonBase>
                            <Divider sx={{ height: 28, m: 0.5, ml: 0.5, mr: 1 }} orientation="vertical" />
                            <Menu open={Boolean(anchorEl)} onClose={closeTypeSelect} anchorEl={anchorEl}>
                                <MenuItem onClick={() => handleTypeChange(EmployeeCommissionType.Fixed)}>$</MenuItem>
                                <MenuItem onClick={() => handleTypeChange(EmployeeCommissionType.Percent)}>%</MenuItem>
                            </Menu>
                        </>
                    )
                },
                label: !hideLabel ? 'Comission' : undefined,
                allowNegative: false,
                isAllowed: type === 'percent' ? ({ floatValue }) => (floatValue ?? 0) <= 100 : undefined,
                onBlur,
                error: !!error,
                helperText: error
            }}
            toggleLabel="Set Commission"
            fullWidth={!onToggle}
        />
    );
};

export default CommissionSelect;
