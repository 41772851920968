import WaiverCreator from './WaiverCreator';
import { Button } from '@mui/material';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate } from 'react-router-dom';
import ErrorCatcher from '../../ui-component/error-catcher';

const WaiverCreate = () => {
    const navigate = useNavigate();

    return (
        <ErrorCatcher wrappedComponentName="WaiverCreate">
            <MainCard
                title="Form"
                secondary={
                    <Button size="small" disableElevation onClick={() => navigate(-1)}>
                        <ChevronLeftOutlinedIcon />
                        Go back
                    </Button>
                }
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
                contentSX={{ p: { xs: 1.5, sm: 3 }, flexGrow: 1 }}
            >
                <WaiverCreator initData={{ title: '', data: null }} />
            </MainCard>
        </ErrorCatcher>
    );
};

export default WaiverCreate;
