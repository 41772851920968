import React, { useCallback, useState } from 'react';
import useCustomerAppointments from './hooks/useCustomerAppointments';
import { Button, ButtonGroup, Box, CircularProgress, Typography } from '@mui/material';
import AppointmentRow from './elements/AppointmentRow';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '../../hooks/redux';

interface CustomerAppointmentsProps {
    customerId: number;
    onViewAppointment?: (id: number) => void;
}

export type CustomerAppointmentsType = 'upcoming' | 'past';

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
    background: 'white',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '8px',

    '& .MuiButton-root': {
        border: 'none !important',
        color: theme.palette.grey[800],

        '&.MuiButton-contained': {
            background: theme.palette.grey[200],
            boxShadow: 'none'
        }
    }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    textTransform: 'capitalize',
    color: theme.palette.grey[600],
    fontSize: '1.2rem',
    margin: `${theme.spacing(5)} 0`
}));

const CustomerAppointments = ({ customerId, onViewAppointment }: CustomerAppointmentsProps) => {
    const { selectedEvent } = useAppSelector((state) => state.calendar);
    const [type, setType] = useState<CustomerAppointmentsType>('upcoming');
    const { appointments, getAppointments, cursor, isLoading } = useCustomerAppointments({ customerId, type });

    const onScroll = useCallback(
        (event: React.UIEvent<HTMLDivElement>) => {
            const element = event.target as HTMLDivElement;
            if (element.scrollHeight - element.scrollTop === element.clientHeight && cursor && !isLoading) {
                getAppointments();
            }
        },
        [cursor, getAppointments, isLoading]
    );

    return (
        <>
            <StyledButtonGroup variant="outlined" fullWidth>
                <Button variant={type === 'upcoming' ? 'contained' : 'outlined'} onClick={() => setType('upcoming')}>
                    Upcoming
                </Button>
                <Button variant={type === 'past' ? 'contained' : 'outlined'} onClick={() => setType('past')}>
                    Past
                </Button>
            </StyledButtonGroup>
            <Box onScroll={onScroll} sx={{ maxHeight: '400px', overflowY: 'auto' }} mt={2} position="relative">
                {appointments.map((a) => (
                    <AppointmentRow
                        key={`ar-${a.id}`}
                        appointment={a}
                        onViewAppointment={onViewAppointment && selectedEvent?.id !== a.id ? () => onViewAppointment(a.id) : undefined}
                    />
                ))}
                {!isLoading && !appointments.length && <StyledTypography>No {type} Appointments</StyledTypography>}
                {isLoading && (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </>
    );
};

export default CustomerAppointments;
