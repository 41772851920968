import { Grid, Menu, MenuItem, Pagination, Button, useTheme } from '@mui/material';
import { gridSpacing } from '../store/constant';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import React from 'react';

export interface IPagePagination {
    data: any;
    perPage: number;
    setPageSize?: (limit?: number) => void;
    page: number;
    setPage: (arg: number) => void;
    additionalContent?: React.ReactNode | string;
}

const AppPagePagination = ({ data, perPage, setPageSize, setPage, page, additionalContent }: IPagePagination) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);

    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePageSizeChange = (size?: number) => {
        setPageSize?.(size);
        setAnchorEl(null);
    };

    return (
        <Grid item xs={12} sx={{ px: { xs: 0, sm: 3 }, py: { xs: 2, sm: gridSpacing } }}>
            <Grid container justifyContent="space-between" spacing={{ xs: 1, sm: 3 }} alignItems="center">
                <Grid item>
                    <Pagination
                        page={page}
                        onChange={(event, selectedPage) => setPage(selectedPage)}
                        count={data?.meta?.last_page}
                        color="secondary"
                        shape="rounded"
                    />
                </Grid>
                {additionalContent && (
                    <Grid item ml={2}>
                        {additionalContent}
                    </Grid>
                )}
                {setPageSize ? (
                    <Grid item>
                        <Button
                            size="large"
                            sx={{ color: theme.palette.grey[900] }}
                            color="secondary"
                            endIcon={<ExpandMoreRoundedIcon />}
                            onClick={handleClick}
                        >
                            {perPage}
                        </Button>
                        <Menu
                            id="menu-user-list-style1"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            variant="selectedMenu"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                        >
                            {[10, 25, 100].map((item) => (
                                <MenuItem key={item} onClick={() => handlePageSizeChange(item)}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    );
};

export default AppPagePagination;

export class PagePagination {}
