import { useMemo, useState, useCallback, RefObject, useEffect } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import Menu from './elements/Menu';
import Details from './elements/tabs-content/details';
import Activity from './elements/tabs-content/Activity';
import Messages from './elements/tabs-content/Messages';
import CustomerInfo from './elements/tabs-content/customer-info';
import Payments from './elements/tabs-content/payments';
import { styled } from '@mui/material/styles';
import { Typography, Grid, Divider, Box, Alert, Link, CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import moment from 'moment/moment';
import { setShouldSubmitFormOnTabChange } from '../../../../store/slices/calendarSlice';
import { useForceMobileLayoutContext } from '../../../../ui-component/force-mobile-layout-context';
import { useSearchParams } from 'react-router-dom';
import AppointmentProducts from './elements/tabs-content/products/AppointmentProducts';
import { openConfirmPopup } from '../../../../store/confirmPopupSlice';

const StyledSidebar = styled(Grid)(({ theme }) => ({
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.down('md')]: {
        borderRight: 'none',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    '&.forceMobile': {
        borderRight: 'none',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    }
}));

const StyledContent = styled(Grid)(({ theme }) => ({
    paddingLeft: '0 !important',

    '& ::-webkit-scrollbar': {
        height: '5px',
        width: '5px'
    },

    '& ::-webkit-scrollbar-track': {
        borderRadius: '3px',
        backgroundColor: theme.palette.grey[200]
    },

    '& ::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.grey[400],
        borderRadius: '3px'
    },

    [theme.breakpoints.down('md')]: {
        paddingLeft: `${theme.spacing(2)} !important`
    },
    '&.forceMobile': {
        paddingLeft: `${theme.spacing(2)} !important`
    }
}));

interface AppointmentDetailsProps {
    submitButtonRef?: RefObject<HTMLButtonElement>;
    isLoading?: boolean;
}

const AppointmentDetails = ({ submitButtonRef, isLoading }: AppointmentDetailsProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const forceMobile = useForceMobileLayoutContext();
    const [activeTab, setActiveTab] = useState<string>('details');
    const matchMd = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('md')) || forceMobile;
    const { selectedEvent, shouldSubmitFormOnTabChange, isForeignAppointment } = useAppSelector((state) => state.calendar);
    const { selectedLocation } = useAppSelector((state) => state.calendarFilter);
    const dispatch = useAppDispatch();

    const content = useMemo(() => {
        switch (activeTab) {
            case 'activity':
                return <Activity />;
            case 'customer-info':
                return <CustomerInfo onResetTabs={() => setActiveTab('details')} />;
            case 'messages':
                return <Messages />;
            case 'products':
                return <AppointmentProducts />;
            case 'payments':
                return <Payments />;
            case 'details':
            default:
                return <Details />;
        }
    }, [activeTab]);

    const handleClickTab = useCallback(
        (newValue: string) => {
            if (shouldSubmitFormOnTabChange && !isForeignAppointment) {
                dispatch(
                    openConfirmPopup({
                        text: 'Save changes before switching tab?',
                        confirmText: 'Save Changes',
                        cancelText: 'Discard Changes',
                        onConfirm: () => {
                            submitButtonRef?.current?.click();
                            dispatch(setShouldSubmitFormOnTabChange(false));
                            setActiveTab(newValue);
                        },
                        onClose: () => {
                            setActiveTab(newValue);
                            dispatch(setShouldSubmitFormOnTabChange(false));
                        }
                    })
                );
            } else {
                setActiveTab(newValue);
            }
        },
        [shouldSubmitFormOnTabChange, isForeignAppointment, submitButtonRef, dispatch]
    );

    useEffect(() => {
        const tab = searchParams.get('tab');
        if (tab === 'messages') {
            setActiveTab('messages');
            setSearchParams(new URLSearchParams());
        }
    }, [searchParams, setSearchParams]);

    return (
        <Grid container spacing={2} mt={0} pt={0} height="100%" alignContent={matchMd ? 'flex-start' : 'inherit'}>
            <StyledSidebar
                item
                xs={12}
                md={forceMobile ? 12 : 4}
                pb={1}
                height={matchMd ? '73px' : 'auto'}
                className={forceMobile ? 'forceMobile' : undefined}
            >
                <Menu
                    activeTab={activeTab}
                    handleClickTab={handleClickTab}
                    has_waiver={selectedEvent?.has_waiver}
                    deposit_request={selectedEvent?.deposit_request}
                    disabled={isLoading}
                />
                {!matchMd && (
                    <Box mt={2}>
                        <Divider />
                        <Box px={2} pt={1}>
                            <Typography variant="subtitle1">Booking info</Typography>
                            <Typography variant="subtitle2" sx={{ fontSize: '0.875rem' }}>
                                Created: {moment(selectedEvent?.created_at).format('ddd, MMM D, hh:mm')}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </StyledSidebar>
            <StyledContent
                className={forceMobile ? 'forceMobile' : undefined}
                item
                xs={12}
                md={forceMobile ? 12 : 8}
                pb={2}
                height={matchMd ? 'calc(100% - 73px)' : '100%'}
                overflow="auto"
            >
                {!isLoading && !isForeignAppointment && selectedLocation && selectedEvent?.location.id !== selectedLocation.id ? (
                    <Box px={2} mb={1}>
                        <Alert severity="info">This appointment is for {selectedEvent?.location.name}</Alert>
                    </Box>
                ) : null}
                {!isLoading && isForeignAppointment && !!selectedEvent?.foreign_company ? (
                    <Box px={2} mb={1}>
                        <Alert severity="info">
                            This appointment is for{' '}
                            <Link href={`/calendar/organization/${selectedEvent?.foreign_company.id}/appointment/${selectedEvent?.id}/`}>
                                <>{selectedEvent?.foreign_company.name}</>
                            </Link>
                        </Alert>
                    </Box>
                ) : null}
                {!isLoading ? (
                    content
                ) : (
                    <Box sx={{ p: 3, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
            </StyledContent>
        </Grid>
    );
};

export default AppointmentDetails;
