import { Autocomplete, Box, Grid, GridProps, TextField } from '@mui/material';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import useExtendedFormik from '../../../../../../../hooks/useExtendedFormik';
import { FC, useCallback, useMemo } from 'react';
import useProductsOptions from '../../../../../../../hooks/options/useProductsOptions';
import { useForceMobileLayoutContext } from '../../../../../../../ui-component/force-mobile-layout-context';
import * as Yup from 'yup';
import { IProduct } from '../../../../../../../models/IProduct';
import FocusTextField from '../../../../../../../ui-component/FocusTextField';

export type AppointmentProductFormType = {
    productId: number;
    amount: number;
    price: number;
};

interface IAppointmentProductFormProps {
    product?: AppointmentProductFormType;
    onSubmit: (data: AppointmentProductFormType) => void;
    isBusy?: boolean;
    excludedValues?: number[];
    productData?: IProduct | null;
}

const AppointmentProductForm: FC<IAppointmentProductFormProps> = ({ product, onSubmit, isBusy, excludedValues, productData }) => {
    const forceMobile = useForceMobileLayoutContext();
    const { options, isLoading, getOptionById } = useProductsOptions(excludedValues, !!productData);
    const { values, setFieldValue, touched, handleBlur, handleSubmit, errors } = useExtendedFormik<AppointmentProductFormType>({
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: true,
        initialValues: product ?? { productId: 0, amount: 0, price: 0 },
        validationSchema: Yup.object().shape({
            productId: Yup.number()
                .typeError('Product is a required field')
                .required('Product is a required field')
                .min(1, 'Product is a required field'),
            amount: Yup.number().required().min(1).label('Amount'),
            price: Yup.number().required().min(0).label('Price')
        }),
        onSubmit
    });

    const columnProps = useMemo<{ large: Pick<GridProps, 'sm' | 'xs'>; small: Pick<GridProps, 'sm' | 'xs'> }>(
        () => ({
            large: forceMobile ? { xs: 12 } : { xs: 12, sm: 6 },
            small: forceMobile ? { xs: 6 } : { xs: 6, sm: 3 }
        }),
        [forceMobile]
    );
    const productValue = useMemo(() => {
        if (productData) {
            return productData;
        }

        return getOptionById(values.productId) ?? null;
    }, [getOptionById, productData, values.productId]);

    const productUnits = productValue?.units;
    const amountLabel = useMemo(() => (productUnits ? `Amount (${productUnits})` : 'Amount'), [productUnits]);

    const handleProductChange = useCallback(
        (_e: unknown, v: IProduct | null) => {
            setFieldValue('productId', v?.id ?? null);
            if (v && values.amount) {
                setFieldValue('price', v.unit_price * values.amount);
            }
        },
        [setFieldValue, values.amount]
    );

    const handleAmountChange = useCallback(
        (v: NumberFormatValues) => {
            setFieldValue('amount', v.floatValue);
            if (productValue) {
                setFieldValue('price', (v.floatValue ?? 0) * productValue.unit_price);
            }
        },
        [productValue, setFieldValue]
    );

    const handlePriceChange = useCallback(
        (v: NumberFormatValues) => {
            setFieldValue('price', v.floatValue);
        },
        [setFieldValue]
    );

    return (
        <Box component="form" onSubmit={handleSubmit} id="appointment-product-form">
            <Grid container spacing={2}>
                <Grid item {...columnProps.large}>
                    {/* If we editing product attached to appointment, we don't need render select */}
                    {productData ? (
                        <TextField label="Product" value={productData.name} fullWidth disabled />
                    ) : (
                        <Autocomplete
                            onChange={handleProductChange}
                            value={productValue}
                            getOptionLabel={(option) => option.name}
                            options={options}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id="productId"
                                    name="productId"
                                    label={isLoading ? 'Loading...' : 'Product'}
                                    error={!!errors.productId && touched.productId}
                                    helperText={touched.productId ? errors.productId : undefined}
                                    onBlur={handleBlur}
                                />
                            )}
                            loading={isLoading}
                            disabled={isLoading || isBusy}
                        />
                    )}
                </Grid>
                <Grid item {...columnProps.small}>
                    <NumberFormat
                        id="price"
                        name="price"
                        customInput={FocusTextField}
                        label="Price"
                        value={values.price}
                        prefix="$"
                        decimalScale={2}
                        fixedDecimalScale
                        allowNegative={false}
                        onValueChange={handlePriceChange}
                        onBlur={handleBlur}
                        disabled={isBusy}
                        fullWidth
                        error={!!errors.price && touched.price}
                        helperText={touched.price ? errors.price : undefined}
                    />
                </Grid>
                <Grid item {...columnProps.small}>
                    <NumberFormat
                        id="amount"
                        name="amount"
                        customInput={FocusTextField}
                        label={amountLabel}
                        value={values.amount}
                        decimalScale={0}
                        fixedDecimalScale
                        allowNegative={false}
                        onValueChange={handleAmountChange}
                        onBlur={handleBlur}
                        disabled={isBusy}
                        fullWidth
                        error={!!errors.amount && touched.amount}
                        helperText={touched.amount ? errors.amount : undefined}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default AppointmentProductForm;
