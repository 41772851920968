import { FC, useMemo, useState } from 'react';
import companyAPI from '../../../services/CompanyService';
import { Box, Grid, Stack, Typography, Link, Alert } from '@mui/material';
import AppPagePagination from '../../../ui-component/PagePagination';
import ApiKeyCreate from './ApiKeyCreate';
import ApiKeyTableRow from './ApiKeyTableRow';
import CBTable from '../../../ui-component/tables/CBTable';
import config from '../../../config';

const ApiKeysList: FC<{ companyId: number }> = ({ companyId }) => {
    const [page, setPage] = useState<number>(1);
    const { data, isLoading } = companyAPI.useGetCompanyApiKeysQuery({ companyId, params: { page, per_page: 5 } });

    const rows = useMemo(() => (data ? data.data : []), [data]);

    return (
        <Box>
            <Stack direction="row" spacing={2}>
                <Typography variant="h4" color="primary" sx={{ flexGrow: 1, flexShrink: 1 }}>
                    API Keys for External Requests
                </Typography>

                <Alert severity="info">
                    Your <strong>companyId</strong> param is: &quot;<strong>{companyId}</strong>&quot;. See&nbsp;
                    <Link href={`${config.apiUrl}/docs/api`} target="_blank">
                        API Docs
                    </Link>
                </Alert>

                <ApiKeyCreate companyId={companyId} />
            </Stack>

            <CBTable
                rows={rows}
                columns={['Name', 'Created At', 'Last Used At', '']}
                renderRow={(row) => <ApiKeyTableRow row={row} companyId={companyId} key={row.id} />}
                isLoading={isLoading}
            />
            <Grid container spacing={3} sx={{ pt: 2 }}>
                <AppPagePagination data={data} perPage={5} page={page} setPage={setPage} />
            </Grid>
        </Box>
    );
};

export default ApiKeysList;
