import { useTheme } from '@material-ui/core';
import { Alert, Box, Button, FormHelperText, Grid, Paper, Stack, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import PlanSelection from './register-wizard/PlanSelection';
import InitAPI from '../../services/InitService';
import * as Yup from 'yup';
import OrganizationSizeButton from './register-wizard/OrganizationSizeButton';
import { RegistrationFormType } from './register-wizard/types';
import UserDataSubform from './register-wizard/subforms/UserDataSubform';
import CompanyDataSubform from './register-wizard/subforms/CompanyDataSubform';
import { LeadData } from './types';
import { RegisterPayload } from '../../contexts/SanctumContext';
import moment from 'moment-timezone';
import useAuth from '../../hooks/useAuth';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';
import { useAppDispatch } from '../../hooks/redux';
import { SnackBarTypes } from '../../store/snackbarReducer';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import useExtendedFormik from '../../hooks/useExtendedFormik';
import { useSearchParams } from 'react-router-dom';
import { SubscriptionType } from '../../models/ICompany';

type RegisterStep = {
    title: string;
    subtitle?: string;
    fields: Array<keyof RegistrationFormType>;
};

const steps: RegisterStep[] = [
    {
        title: "Let's get started",
        subtitle: 'Just a few short questions and you’ll have access to Chilled Butter, instantly.',
        fields: []
    },
    { title: "What's the name of your business?", fields: ['company_name', 'industry', 'address'] },
    { title: 'How many service providers do you have?', fields: ['company_size'] },
    {
        title: 'Confirm Plan Selection',
        fields: ['stripe_price_id', 'subscription_type']
    }
];

export const registrationFormSchema = Yup.object().shape({
    firstname: Yup.string().trim().required().label('First Name'),
    lastname: Yup.string().trim().required().label('Last Name'),
    email: Yup.string().trim().email().required().label('Email'),
    company_name: Yup.string().trim().required().label('Business Name'),
    industry: Yup.string().trim().required().label('Industry'),
    company_size: Yup.string().trim().required('Please select service providers count'),
    address: Yup.object().shape({
        address: Yup.string().trim().required().label('Address')
    }),
    stripe_price_id: Yup.string().trim().required().label('Payment Plan')
});

const Register: FC<{ withTrial?: boolean }> = ({ withTrial = false }) => {
    const [activeStep, setActiveStep] = useState(0);
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();

    const [searchParams] = useSearchParams();
    const price = useMemo(() => searchParams.get('price'), [searchParams]);

    const { data: initData } = InitAPI.useInitQuery({ params: price ? { stripe_price_id: price } : undefined });
    const noPlansAvailable = useMemo(() => {
        if (initData) {
            return !initData.stripe.products.length;
        }

        return false;
    }, [initData]);

    const agencyPlan = useMemo(() => {
        if (initData) {
            const { products } = initData.stripe;
            return products.length === 1 && products[0].metadata.limitName === SubscriptionType.Agency ? products[0] : null;
        }

        return null;
    }, [initData]);

    const { register } = useAuth();

    const handleRegister = useCallback(
        (formData: RegistrationFormType) => {
            const { firstname, lastname, email, address, industry, company_name, subscription_type, lead_uuid, stripe_price_id } = formData;
            const payload: RegisterPayload = {
                firstname,
                lastname,
                email,
                address: {
                    address: address.address || '',
                    city: address.city || '',
                    state: address.state || '',
                    postal_code: address.postal_code || '',
                    l1: address?.l1 || undefined,
                    l2: address?.l2 || undefined,
                    country: address.country || null
                },
                company: {
                    name: company_name,
                    industry,
                    lead_uuid,
                    time_zone: moment.tz.guess(true),
                    subscription_type
                },
                stripe_price_id,
                with_trial: withTrial
            };

            dispatch(startSubmitting());
            register(payload)
                .then(() => {
                    dispatch(stopSubmitting());
                })
                .catch((err) => {
                    dispatch(stopSubmitting());
                    showSnackbar({
                        message: err.message || 'Error occurred, please check your data and try again',
                        alertSeverity: SnackBarTypes.Error
                    });
                });
        },
        [dispatch, register, showSnackbar, withTrial]
    );

    const formik = useExtendedFormik<RegistrationFormType>({
        enableReinitialize: true,
        initialValues: {
            firstname: '',
            lastname: '',
            email: '',
            company_name: '',
            industry: '',
            company_size: '',
            address: {
                address: '',
                city: '',
                state: '',
                country: '',
                postal_code: ''
            },
            stripe_price_id: '',
            subscription_type: '',
            lead_uuid: ''
        },
        validationSchema: registrationFormSchema,
        onSubmit: handleRegister
    });

    const { values, setValues, errors, touched, validateForm, handleSubmit, setFieldTouched, setFieldValue } = formik;

    const submitBtnLabel = useMemo(() => {
        if (!activeStep) {
            return 'Continue';
        }

        if (activeStep === steps.length - 1) {
            return 'Sign Up';
        }

        return 'Next';
    }, [activeStep]);

    const handleNextStep = useCallback(() => {
        if (activeStep === steps.length - 1) {
            handleSubmit();
            return;
        }

        validateForm().then((res) => {
            const { fields } = steps[activeStep];
            const invalidFields = fields.filter((field) => !!res[field]);
            invalidFields.forEach((field) => setFieldTouched(field, true));
            if (!invalidFields.length) {
                if (activeStep === 1 && agencyPlan) {
                    setFieldValue('company_size', 'large');
                    setActiveStep(3);
                } else {
                    setActiveStep((prev) => (prev + 1 > steps.length - 1 ? 0 : prev + 1));
                }
            }
        });
    }, [activeStep, validateForm, handleSubmit, setFieldTouched, agencyPlan, setFieldValue]);

    const handleSaveLeadData = useCallback(
        ({ firstname, lastname, email, id }: LeadData) => {
            setValues({ ...values, firstname, lastname, email, lead_uuid: id }, false);
            setActiveStep(1);
        },
        [setValues, values]
    );

    const handleSetStripePriceId = useCallback((v: string) => setFieldValue('stripe_price_id', v), [setFieldValue]);
    const handleSetSubscriptionType = useCallback((v: string) => setFieldValue('subscription_type', v), [setFieldValue]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 3,
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary.light,
                minHeight: '100vh'
            }}
        >
            <Paper elevation={10} sx={{ p: 4, width: '600px', maxWidth: '100%' }}>
                {noPlansAvailable ? (
                    <Alert severity="error">No subscription plans available, please contact support.</Alert>
                ) : (
                    <Stack spacing={4}>
                        <Box>
                            <Typography textAlign="center" gutterBottom variant="body1" sx={{ mb: 1 }}>
                                Step {activeStep + 1} of {steps.length}
                            </Typography>
                            <Typography textAlign="center" variant="h1" gutterBottom color={theme.palette.secondary.main}>
                                {steps[activeStep].title}
                            </Typography>
                            {steps[activeStep].subtitle ? (
                                <Typography textAlign="center" gutterBottom>
                                    {steps[activeStep].subtitle}
                                </Typography>
                            ) : null}
                        </Box>

                        {activeStep === 0 ? (
                            <Box>
                                <UserDataSubform onSuccess={handleSaveLeadData} />
                            </Box>
                        ) : (
                            <>
                                <form autoComplete="off">
                                    <Grid container spacing={2}>
                                        {activeStep === 1 && <CompanyDataSubform formInstance={formik} />}

                                        {activeStep === 2 && (
                                            <>
                                                {['single_user', 'small', 'large'].map((item) => (
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        key={item}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column'
                                                        }}
                                                    >
                                                        <OrganizationSizeButton
                                                            type={item}
                                                            selected={values.company_size === item}
                                                            onSelect={(type) => {
                                                                setFieldValue('company_size', type);
                                                            }}
                                                        />
                                                    </Grid>
                                                ))}
                                                {touched.company_size && errors.company_size ? (
                                                    <Grid item xs={12}>
                                                        <FormHelperText error>{errors.company_size}</FormHelperText>
                                                    </Grid>
                                                ) : null}
                                            </>
                                        )}

                                        {activeStep === 3 && initData && (
                                            <Grid item xs={12}>
                                                <Box>
                                                    <PlanSelection
                                                        companySize={values.company_size}
                                                        initData={initData}
                                                        setStripePriceId={handleSetStripePriceId}
                                                        subscriptionType={values.subscription_type}
                                                        setSubscriptionType={handleSetSubscriptionType}
                                                    />
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </form>

                                <Stack direction="row" spacing={2} sx={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                    <Button variant="contained" size="large" onClick={handleNextStep}>
                                        {submitBtnLabel}
                                    </Button>
                                </Stack>
                            </>
                        )}
                    </Stack>
                )}
            </Paper>
        </Box>
    );
};

export default Register;
