import CBModal from '../../../../cb-modal/CBModal';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { cancellationReasons } from '../../../../../store/constant';
import { useCallback, useState } from 'react';
import { CancellationReason } from '../../../../../views/calendar/types';
import { AppointmentStatuses } from '../../../../../models/IAppointment';

interface CancellationReasonProps {
    isOpen: boolean;
    close: () => void;
    updateStatus: (status: AppointmentStatuses, reason?: CancellationReason) => void;
}

const CancellationReasonSelect = ({ isOpen, close, updateStatus }: CancellationReasonProps) => {
    const [reason, setReason] = useState<CancellationReason | undefined>();

    const handleChangeReason = (e: SelectChangeEvent) => {
        setReason(e.target.value as CancellationReason);
    };

    const handleClickOk = useCallback(() => {
        updateStatus(AppointmentStatuses.Canceled, reason);
        close();
    }, [close, reason, updateStatus]);

    return (
        <CBModal
            maxWidth="xs"
            title="Cancel Appointment"
            open={isOpen}
            onClose={close}
            closeButtonText="Close"
            okButtonText="Confirm"
            onClickOk={handleClickOk}
            okButtonDisabled={!reason}
        >
            <Typography align="center" mb={2}>
                Cancellation Reason
            </Typography>
            <FormControl fullWidth>
                {/* @ts-ignore */}
                <InputLabel id="cancel-reason-select">Reason</InputLabel>
                <Select id="cancel-reason-select" value={reason || ''} label="Reason" onChange={handleChangeReason}>
                    {Object.values(cancellationReasons).map((reasonOption) => (
                        <MenuItem key={reasonOption.title} value={reasonOption.value}>
                            {reasonOption.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </CBModal>
    );
};

export default CancellationReasonSelect;
