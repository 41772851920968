import { Box, Stack, TextField } from '@mui/material';
import { FC, Fragment, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BookingWidgetAnswer } from '../../views/scheduling-widget/widget-wizard/types';

type WidgetSurveyFormType = BookingWidgetAnswer[];

type WidgetSurveyFormProps = {
    formId: string;
    data: WidgetSurveyFormType;
    onSubmit: (data: WidgetSurveyFormType) => void;
};

const schema = Yup.array().of(
    Yup.object().shape({
        answers: Yup.array().of(
            Yup.object().shape({
                answer: Yup.string().trim().required('This field is required')
            })
        )
    })
);

const WidgetSurveyForm: FC<WidgetSurveyFormProps> = ({ formId, data, onSubmit }) => {
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik<WidgetSurveyFormType>({
        initialValues: data,
        onSubmit,
        validationSchema: schema
    });

    const getError = useCallback(
        (serviceIndex: number, questionIndex: number) => {
            const isTouched = !!touched?.[serviceIndex]?.answers?.[questionIndex];
            const err = errors?.[serviceIndex]?.answers?.[questionIndex];

            return isTouched && typeof err !== 'string' ? err?.answer : undefined;
        },
        [touched, errors]
    );

    return (
        <Stack spacing={2} id={formId} component="form" onSubmit={handleSubmit}>
            {values.map((service, serviceIndex) => (
                <Fragment key={service.service_id}>
                    {service.answers.map((element, index) => (
                        <Box key={`${service.service_id}_${element.title}_${index}`}>
                            <Box sx={{ fontWeight: 600, mb: 0.5 }}>{element.title}</Box>
                            <TextField
                                aria-label={element.title}
                                id={`${serviceIndex}.answers.${index}.answer`}
                                name={`${serviceIndex}.answers.${index}.answer`}
                                multiline
                                rows={4}
                                value={element.answer ?? ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                error={!!getError(serviceIndex, index)}
                                helperText={getError(serviceIndex, index)}
                            />
                        </Box>
                    ))}
                </Fragment>
            ))}
        </Stack>
    );
};

export default WidgetSurveyForm;
