import { FormHelperText, Grid } from '@material-ui/core';
import CustomShiftsTable from './CustomShiftsTable';
import { IGroupedCustomShift } from '../../models/IEmployee';
import TwoColumnsSwitch from '../../ui-component/TwoColumnsSwitch';
import InfoTooltip from '../../ui-component/InfoTooltip';
import { FormikErrors } from 'formik';
import { EmployeeSubFormValueType } from './use-employee-form-schema';

interface StaffShiftsProps {
    is_shifts_enabled: boolean;
    setFieldValue: (fieldName: string, value: any) => void;
    checkboxFieldName: string;
    shifts: IGroupedCustomShift[];
    setShifts: (data: IGroupedCustomShift[]) => void;
    error: string | null;
    errors?: FormikErrors<EmployeeSubFormValueType>;
    label: string;
    tooltip: string;
}

const StaffShifts = ({
    checkboxFieldName,
    is_shifts_enabled,
    setShifts,
    shifts,
    setFieldValue,
    error,
    errors,
    label,
    tooltip
}: StaffShiftsProps) => (
    <>
        <TwoColumnsSwitch
            value={is_shifts_enabled}
            fieldName={checkboxFieldName}
            setFieldValue={setFieldValue}
            label={label}
            labelDecoration={<InfoTooltip text={tooltip} />}
        />
        {is_shifts_enabled && (
            <>
                <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }} />
                <Grid item xs={12} sm={9} lg={8} sx={{ overflow: 'auto' }}>
                    {error && (
                        <FormHelperText error id="error-shifts">
                            {error}
                        </FormHelperText>
                    )}
                    <CustomShiftsTable shifts={shifts} setShifts={setShifts} errors={errors} />
                </Grid>
            </>
        )}
    </>
);

export default StaffShifts;
