import { FormControl, TextField } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { SurveyCreatorComponent, SurveyCreator } from 'survey-creator-react';
import 'assets/css/defaultV2.min.css';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import waiverAPI from 'services/WaiverService';
import { IWaiver } from 'models/ICompany';
import { registerRichEdit } from './RichEdit';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';
import SurveyQuestionRichEdit, { getRichEditHtml } from './TynimceEditor';
import { useNavigate } from 'react-router-dom';
import { setLicenseKey } from 'survey-core';
import registerCamera from './CameraBlock';
import { Box } from '@mui/material';

interface WaiverCreatorProps {
    initData: IWaiver;
}

const WaiverCreator = ({ initData }: WaiverCreatorProps) => {
    const [updateWaiver] = waiverAPI.useUpdateWaiverMutation();
    const [createWaiver] = waiverAPI.useCreateWaiverMutation();
    const { showSnackbar } = useShowSnackbar();
    const [value, setValue] = useState(initData.data);
    const [title, setTitle] = useState(initData.title);
    const [stateCreator, setStateCreator] = useState<null | SurveyCreator>(null);
    const navigate = useNavigate();

    const saveContent = () => {
        const waiverSavedData = JSON.parse(String(value));
        waiverSavedData?.pages?.forEach((page: any) => {
            page.elements.forEach((element: any) => {
                if (element.type === 'richedit') {
                    SurveyQuestionRichEdit.handleEditorChange(element.html, element.name);
                }
            });
        });
    };

    const creatorOptions = {
        showJSONEditorTab: false
    };

    const save = useCallback(
        (saveNo: any, callback: any) => {
            if (!stateCreator) return;

            const waiverData = JSON.parse(stateCreator.text);

            const updatedPages = waiverData?.pages?.map((page: any) => {
                const updatedElements = page?.elements?.map((element: any) => {
                    if (element.type === 'richedit') {
                        const htmlContent = getRichEditHtml(element.name);

                        if (htmlContent) {
                            return {
                                ...element,
                                html: htmlContent
                            };
                        }
                    }
                    return element;
                });

                return {
                    ...page,
                    elements: updatedElements
                };
            });
            const waiver: IWaiver = {
                title,
                data: JSON.stringify({ ...waiverData, pages: updatedPages })
            };

            if (initData.id) waiver.id = initData.id;

            setValue(waiver.data);

            const saveWaiver = initData.id ? updateWaiver(waiver) : createWaiver(waiver);

            saveWaiver
                .unwrap()
                .then(() => {
                    showSnackbar({
                        message: `Form ${initData.id ? 'updated' : 'created'}`,
                        alertSeverity: SnackBarTypes.Success
                    });
                    navigate('/forms');
                })
                .catch((err) => {
                    stateCreator.undo();
                    stateCreator.redo();
                    showSnackbar({
                        message: `${err.data}`,
                        alertSeverity: SnackBarTypes.Error
                    });
                });
            callback(saveNo, true);
        },
        [createWaiver, initData.id, navigate, showSnackbar, stateCreator, title, updateWaiver]
    );

    useEffect(() => {
        if (!stateCreator) return;

        const newCreator = stateCreator;
        newCreator.saveSurveyFunc = save;
        setStateCreator(newCreator);
    }, [stateCreator, title, save]);

    const initCreator = () => {
        const creator = new SurveyCreator(creatorOptions);
        creator.text = value || '';

        creator.toolbox.removeItem('html');
        creator.toolbox.forceCompact = true;
        creator.showSidebar = false;

        creator.saveSurveyFunc = save;
        setStateCreator(creator);
    };

    const surveyJsKey = process.env.REACT_APP_SURVEY_JS_KEY ?? '';

    useEffect(() => {
        saveContent();
        registerRichEdit();
        registerCamera();
        initCreator();

        try {
            if (process.env.NODE_ENV === 'production') setLicenseKey(surveyJsKey);
        } catch {
            console.error('Survey JS license issue');
        }

        return () => setValue(null);
    }, []);

    // Force creator to switch state if WYSIWYG was updated
    useEffect(() => {
        function onUpdate() {
            if (stateCreator) {
                // @ts-ignore
                stateCreator.setState('modified');
            }
        }

        window.addEventListener('richTextUpdated', onUpdate);

        return () => {
            window.removeEventListener('richTextUpdated', onUpdate);
        };
    }, [stateCreator]);

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <FormControl sx={{ alignSelf: 'flex-start' }}>
                <TextField name="title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Form title" />
            </FormControl>
            {stateCreator && (
                <Box sx={{ flexGrow: 1 }}>
                    <SurveyCreatorComponent creator={stateCreator} />
                </Box>
            )}
        </Box>
    );
};

export default WaiverCreator;
