import * as Yup from 'yup';
import { PaymentType } from '../../models/IService';
import { min_service_reschedule_interval } from '../../store/constant';
import { WaiverSignType } from '../../models/ICompany';

const servicePolicySchema = Yup.object().shape({
    enabled: Yup.boolean(),
    policy_title: Yup.mixed()
        .nullable()
        .when('enabled', {
            is: true,
            then: Yup.string().trim().typeError('Policy Title is required').required('Policy Title is required')
        }),
    policy_text: Yup.mixed()
        .nullable()
        .when('enabled', {
            is: true,
            then: Yup.string().trim().typeError('Policy Text is required').required('Policy Text is required')
        })
});

const serviceFormSchema = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    duration: Yup.number()
        .required('Duration is required')
        .min(10, 'Duration must be greater than or equal to 10')
        .max(600, 'Duration must be less than or equal to 600')
        .typeError('Must be a number type'),
    interval: Yup.number()
        .required('Post-Appointment Buffer is required')
        .max(120, 'Post-Appointment Buffer must be less than or equal to 120')
        .typeError('Must be a number type'),
    payment_type: Yup.string().required('Payment type is required'),
    price: Yup.number().when('payment_type', {
        is: (payment_type: string) => payment_type === PaymentType.Paid || payment_type === PaymentType.Prepaid,
        then: Yup.number()
            .required('Service price is required')
            .max(9999, 'Maximum Service price is 9999')
            .typeError('Service price is required'),
        otherwise: Yup.number().nullable()
    }),
    prepay: Yup.number().when('payment_type', {
        is: (payment_type: string) => payment_type === PaymentType.Prepaid,
        then: Yup.number()
            .required('Deposit amount is required')
            .max(9999, 'Maximum deposit amount is 9999')
            .typeError('Deposit amount is required'),
        otherwise: Yup.number().nullable()
    }),
    is_reschedule_enabled: Yup.boolean(),
    rescheduling_interval: Yup.number().when('is_reschedule_enabled', {
        is: true,
        then: Yup.number()
            .required('Service reschedule buffer is required')
            .typeError('Must be a number type')
            .min(min_service_reschedule_interval, `Min service reschedule buffer is ${min_service_reschedule_interval} minutes`)
            .max(2880, 'Max service reschedule buffer is 2880 minutes'),
        otherwise: Yup.number().nullable()
    }),
    advance_booking_buffer: Yup.number()
        .typeError('Must be a number type')
        .min(0, 'Minimum advance booking buffer is 0')
        .required('Advance booking buffer is required'),
    locations: Yup.array().nullable(),
    employees: Yup.array().nullable(),
    images: Yup.array().nullable(),
    description: Yup.string().nullable(),
    schedule: Yup.mixed(),
    confirmation_note: Yup.string().nullable(),
    is_waiver_enabled: Yup.boolean(),
    waivers: Yup.number()
        .nullable()
        .when('is_waiver_enabled', {
            is: true,
            then: Yup.number().typeError('Invalid form type').required('Form is required')
        }),
    waivers_sign_type: Yup.mixed().when('is_waiver_enabled', {
        is: true,
        then: Yup.string()
            .trim()
            .required('Form Sign Type is required field')
            .oneOf(Object.values(WaiverSignType), 'Form Sign Type is required field')
    }),
    use_materials: Yup.boolean().required().label('Materials'),
    materials: Yup.mixed()
        .nullable()
        .notRequired()
        .when('use_materials', {
            is: true,
            then: Yup.object()
                .shape({
                    name: Yup.string().trim().required().label('Material name'),
                    units: Yup.string().trim().required().label('Material units'),
                    price_per_unit: Yup.number().min(0.01).required().label('Price per unit')
                })
                .required()
        }),
    location_type: Yup.string().trim().required().label('Location type'),
    use_service_policy: servicePolicySchema
});

export default serviceFormSchema;
