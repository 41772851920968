import { Grid, Skeleton } from '@material-ui/core';
import UploadField from 'ui-component/file-uploader/UploadField';
import { ReactChild, useState } from 'react';
import { axiosServices } from 'utils/axios';
import { openConfirmPopup } from 'store/confirmPopupSlice';
import { SnackBarTypes } from 'store/snackbarReducer';
import { useAppDispatch } from 'hooks/redux';
import { ImageData } from '../../models/IImage';
import { FormikErrors } from 'formik';
import ImageEditor from 'ui-component/ImageEditor';
import useAuth from '../../hooks/useAuth';
import LabelWithInfo from '../LabelWithInfo';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import ImagePreview from './cb-image-upload/components/ImagePreview';

type Props = {
    initialPreview: ImageData | null;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<any>>;
    clearFormImage: () => void;
    name: string;
    imageFieldName: string;
    infoText?: string | ReactChild;
};

const ImageUploader = ({ initialPreview, setFieldValue, name, imageFieldName, clearFormImage, infoText }: Props) => {
    const dispatch = useAppDispatch();
    const { checkAuthentication } = useAuth();
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [preview, setPreview] = useState<ImageData | null>(initialPreview);

    const { showSnackbar } = useShowSnackbar();

    const deleteImage = async (id: number | undefined) => {
        if (id) {
            try {
                const res = await axiosServices.delete(`/uploader/image/${id}`);
                if (res.data) {
                    showSnackbar({
                        message: 'Image deleted',
                        alertSeverity: SnackBarTypes.Success
                    });
                    checkAuthentication().then(() => {
                        setPreview(null);
                        clearFormImage();
                    });
                }
            } catch (e) {
                showSnackbar({
                    message: e.message ?? e.data ?? JSON.stringify(e),
                    alertSeverity: SnackBarTypes.Error
                });
            }
        }
    };

    const confirmDeleteImage = () => {
        if (preview && preview.id) {
            dispatch(
                openConfirmPopup({
                    onConfirm: () => deleteImage(preview.id),
                    confirmText: `Delete`,
                    text: 'Are you sure you want to delete this image?'
                })
            );
        } else {
            setPreview(null);
            clearFormImage();
        }
    };

    const handleEditClose = () => {
        setIsEditOpen(false);
    };

    const handleResize = () => {
        setIsEditOpen(true);
    };

    return (
        <>
            <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                <LabelWithInfo label={name} infoText={infoText} />
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
                {!isLoading && preview && preview.url ? (
                    <ImagePreview url={preview.url} onResize={handleResize} onDelete={confirmDeleteImage} />
                ) : null}
                {!isLoading && !preview?.url ? (
                    <UploadField
                        imageFieldName={imageFieldName}
                        setFieldValue={setFieldValue}
                        setPreview={setPreview}
                        setIsLoading={setIsLoading}
                    />
                ) : null}
                {isLoading && <Skeleton animation="wave" width="320px" height="240px" />}
            </Grid>
            <ImageEditor
                isOpen={isEditOpen}
                incomingImage={preview}
                imageFieldName="images"
                setFieldValue={setFieldValue}
                setPreview={setPreview}
                closeEditor={handleEditClose}
                setIsLoading={setIsLoading}
            />
        </>
    );
};

export default ImageUploader;
