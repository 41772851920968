// material-ui
import { Button, Grid } from '@material-ui/core';

// assets
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import serviceAPI from 'services/ServiceService';
import { IService, ServiceLocationType } from 'models/IService';
import ServiceForm from './ServiceForm';
import { initServiceSchedule, min_service_reschedule_interval } from '../../store/constant';
import useAuth from '../../hooks/useAuth';
import useInvalidateServices from './use-invalidate-services';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';
import ErrorCatcher from '../../ui-component/error-catcher';

const ServiceCreate: React.FC = () => {
    const { checkAuthentication } = useAuth();
    const navigate = useNavigate();
    const invalidate = useInvalidateServices();
    const [createService] = serviceAPI.useCreateServiceMutation();
    const initValue = ({
        name: '',
        advance_booking_buffer: 30,
        is_reschedule_enabled: true,
        rescheduling_interval: min_service_reschedule_interval,
        duration: 60,
        interval: 0,
        payment_type: '',
        price: null,
        prepay: null,
        locations: [],
        employees: [],
        images: [],
        description: '',
        schedule: initServiceSchedule,
        staff_autoassign: false,
        location_type: ServiceLocationType.Business,
        use_rebook_reminder: false,
        use_service_policy: {
            enabled: false,
            policy_title: '',
            policy_text: ''
        }
    } as unknown) as IService;

    const { showSnackbar } = useShowSnackbar();

    const handleCreate = (service: IService) => {
        createService(service)
            .unwrap()
            .then(() => {
                showSnackbar({
                    message: 'Service created',
                    alertSeverity: SnackBarTypes.Success
                });

                checkAuthentication();
                navigate('/service', { replace: true });
                invalidate();
            })
            .catch((e) => {
                showSnackbar({
                    message: e.data || "Error: Service hasn't created",
                    alertSeverity: SnackBarTypes.Error
                });
            });
    };

    return (
        <ErrorCatcher wrappedComponentName="ServiceCreate">
            <Grid>
                <MainCard
                    title="New Service"
                    secondary={
                        <Button size="small" disableElevation onClick={() => navigate(-1)}>
                            <ChevronLeftOutlinedIcon />
                            Go back
                        </Button>
                    }
                    contentSX={{ p: { xs: 1.5, sm: 3 } }}
                >
                    <ServiceForm service={initValue} update={handleCreate} />
                </MainCard>
            </Grid>
        </ErrorCatcher>
    );
};

export default ServiceCreate;
