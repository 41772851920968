// import { useState } from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

// project imports
import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import WidgetLink from './WidgetLink';

// assets
import { IconMenu2 } from '@tabler/icons';
import SupportWidget from './SupportWidget';
import SearchFilter from '../../../ui-component/search-filter/SearchFilter';
import MobileCreateButton from './MobileCreateButton';
import CopyVideoLink from '../../../ui-component/copy-video-link/CopyVideoLink';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import HeaderNavigation from '../../../ui-component/header-navigation';
import useNavigationMode from '../../use-navigation-mode';
import { Stack } from '@mui/material';
import OrganizationSwitcher from '../OrganizationSwitcher';
import NotificationSection from './NotificationSection';
import useAuth from '../../../hooks/useAuth';
import CommonHeaderBtn from '../../../ui-component/CommonHeaderBtn';
import useCashLedger from '../../../hooks/use-cash-ledger';
import { DialogTypes, openDialog } from '../../../store/slices/entityDialogsSlice';
import { useCallback } from 'react';

// style constant
const useStyles = makeStyles((theme: Theme) => ({
    boxContainer: {
        display: 'flex',
        width: 'auto',
        flexGrow: 0,
        flexShrink: 0
    }
}));

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

export interface HeaderProps {
    handleLeftDrawerToggle: () => void;
    matchSm: boolean;
}

const Header = ({ handleLeftDrawerToggle, matchSm }: HeaderProps) => {
    const { user } = useAuth();
    const dispatch = useAppDispatch();
    const { isLedgerVisible } = useCashLedger();
    const navMode = useNavigationMode();
    const bottomMenuVisible = useMediaQuery('(max-width:768px)');
    const { showSearch } = useAppSelector((state) => state.calendarFilter);
    const classes = useStyles();

    const onOpenLedger = useCallback(() => {
        dispatch(openDialog({ type: DialogTypes.Ledger, id: null }));
    }, [dispatch]);

    return (
        <>
            {/* logo & toggler button */}
            <div className={classes.boxContainer}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1, mr: 1 }}>
                    <LogoSection />
                </Box>
                {navMode === 'sidebar' ? (
                    <CommonHeaderBtn onClick={handleLeftDrawerToggle}>
                        <IconMenu2 stroke={1.5} />
                    </CommonHeaderBtn>
                ) : null}
            </div>

            <Stack direction="row" sx={{ alignItems: 'center', marginLeft: 2, flexGrow: 1, justifyContent: 'flex-end' }} spacing={2}>
                <MobileCreateButton />
                {showSearch && !bottomMenuVisible ? (
                    <Box flexGrow={3} marginRight="auto" sx={{ maxWidth: '500px' }}>
                        <SearchFilter />
                    </Box>
                ) : null}

                {navMode === 'header' ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 0,
                            justifyContent: 'center',
                            mr: 'auto !important',
                            ml: !showSearch ? 'auto !important' : undefined
                        }}
                    >
                        <HeaderNavigation />
                    </Box>
                ) : null}

                <SupportWidget />
                {navMode === 'header' ? (
                    <>
                        <WidgetLink />
                        <CopyVideoLink />
                    </>
                ) : null}

                {isLedgerVisible ? (
                    <CommonHeaderBtn onClick={onOpenLedger}>
                        <CurrencyExchangeIcon />
                    </CommonHeaderBtn>
                ) : null}

                {user && !matchSm ? <OrganizationSwitcher /> : null}
                {user && !bottomMenuVisible ? <NotificationSection /> : null}
                <ProfileSection isMobile={matchSm} />
            </Stack>
        </>
    );
};

export default Header;
