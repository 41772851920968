import { ListItemButton, ListItemText } from '@mui/material';
import { FC, useMemo } from 'react';
import { NavItemWithChildren } from '../../../menu-items';
import { Link } from 'react-router-dom';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { usePopupState, bindFocus, bindHover, bindMenu } from 'material-ui-popup-state/hooks';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HeaderNavItemIcon from './HeaderNavItemIcon';
import commonNavBtnSx from './common-nav-btn-sx';

const HeaderNavGroup: FC<{ item: NavItemWithChildren }> = ({ item }) => {
    const popupState = usePopupState({
        variant: 'popover',
        popupId: `header_${item.id}`
    });

    const GroupIcon = item.icon;
    const open = useMemo(() => bindMenu(popupState).open, [popupState]);

    return (
        <>
            <ListItemButton {...bindHover(popupState)} {...bindFocus(popupState)} selected={open} sx={commonNavBtnSx}>
                <HeaderNavItemIcon Icon={GroupIcon} />
                <ListItemText>{item.title}</ListItemText>
                <HeaderNavItemIcon Icon={open ? KeyboardArrowDownIcon : KeyboardArrowRightIcon} position="end" />
            </ListItemButton>

            <HoverMenu {...bindMenu(popupState)}>
                {item.children.map((child) => {
                    const Icon = child.icon ?? undefined;
                    return (
                        <ListItemButton key={child.id} component={Link} to={child.url} onClick={popupState.close}>
                            <HeaderNavItemIcon Icon={Icon} />
                            <ListItemText>{child.title}</ListItemText>
                        </ListItemButton>
                    );
                })}
            </HoverMenu>
        </>
    );
};

export default HeaderNavGroup;
