// material-ui
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Box, Drawer } from '@material-ui/core';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { drawerWidth } from 'store/constant';
import { Divider, Stack } from '@mui/material';
import WidgetLink from '../Header/WidgetLink';
import CopyVideoLink from '../../../ui-component/copy-video-link/CopyVideoLink';
import { memo } from 'react';

// style constant
const useStyles = makeStyles((theme: Theme) => ({
    // drawer: {
    //     [theme.breakpoints.up('md')]: {
    //         width: drawerWidth,
    //         flexShrink: 0
    //     }
    // },
    drawerPaper: {
        width: drawerWidth,
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderRight: 'none'
    },
    ScrollHeight: {
        height: 'calc(100vh - 80px)',
        paddingLeft: '16px',
        paddingRight: '16px',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 56px)'
        }
    },
    boxContainer: {
        display: 'flex',
        padding: '16px',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
}));

// ==============================|| SIDEBAR DRAWER ||============================== //

export interface SidebarProps {
    drawerOpen?: boolean;
    drawerToggle?: () => void;
}

const Sidebar = ({ drawerOpen, drawerToggle }: SidebarProps) => {
    const classes = useStyles();

    return (
        <nav aria-label="mailbox folders">
            <Drawer
                variant="temporary"
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                classes={{
                    paper: classes.drawerPaper
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <Box py={2}>
                    <Box sx={{ display: { xs: 'block', md: 'none' }, mt: -2, mb: 2 }}>
                        <div className={classes.boxContainer}>
                            <LogoSection />
                        </div>
                    </Box>
                    <Stack alignItems="flex-start" spacing={2} sx={{ width: '100%', p: '0 16px' }} direction="row">
                        <WidgetLink />
                        <CopyVideoLink />
                    </Stack>
                </Box>

                <Divider />
                <Box sx={{ px: 2 }}>
                    <MenuList />
                </Box>
            </Drawer>
        </nav>
    );
};

export default memo(Sidebar);
