import { ImageData } from '../models/IImage';
import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import SliderCarousel from './SliderCarousel';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@material-ui/system';
import useDownloadFile from '../hooks/useDownloadFile';
import { useCallback, useEffect, useMemo } from 'react';
import { deleteImageFromAWS } from '../utils/functions/uploading-images-helpers';
import appointmentAPI from '../services/AppointmentService';
import appointmentWidgetAPI from '../services/WidgetService';
import imageUploadAPI from '../services/ImageUploadService';
import { useAppDispatch } from '../hooks/redux';
import { openConfirmPopup } from '../store/confirmPopupSlice';

interface AttachmentsGalleryProps {
    attachments: ImageData[];
    isOpen: boolean;
    onClose: () => void;
    currImg: number;
    setCurrImg: (index: number) => void;
    disableDelete?: boolean;
}

const iconBtnSx: SxProps = { '&, &:hover': { backgroundColor: '#fff !important' } };

const AttachmentsGallery = ({ attachments, isOpen, onClose, currImg, setCurrImg, disableDelete }: AttachmentsGalleryProps) => {
    const theme = useTheme();
    const { handleDownload } = useDownloadFile();
    const dispatch = useAppDispatch();

    const confirmDeleteImage = (id: number) => {
        dispatch(
            openConfirmPopup({
                onConfirm: () => handleDeleteFile(id),
                confirmText: `Delete`,
                text: `Are you sure you want to delete this image?`
            })
        );
    };

    const handleDeleteFile = useCallback(
        (id: number) => {
            deleteImageFromAWS(id).then(() => {
                dispatch(appointmentAPI.util.invalidateTags(['Appointment']));
                dispatch(appointmentWidgetAPI.util.invalidateTags(['AppointmentWidget']));
                dispatch(imageUploadAPI.util.invalidateTags(['Upload']));
            });
        },
        [dispatch]
    );

    const selected = useMemo<ImageData | null>(() => attachments[currImg] ?? null, [attachments, currImg]);

    // Forces change carousel to last image after delete item
    useEffect(() => {
        if (currImg && !selected) {
            setCurrImg(attachments.length - 1);
        }
    }, [currImg, selected, attachments.length, setCurrImg]);

    return (
        <Dialog
            fullWidth
            fullScreen
            open={isOpen}
            onClose={onClose}
            sx={{
                backgroundColor: '#000',
                '& .MuiPaper-root': {
                    p: 0
                }
            }}
        >
            <DialogTitle
                sx={{
                    p: '12px 24px',
                    backgroundColor: 'primary.main',
                    color: theme.palette.getContrastText(theme.palette.primary.main)
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography sx={{ fontWeight: 'bold', fontSize: '18px', width: '100%' }} textAlign="center">
                        Appointment Attachments
                    </Typography>
                    <IconButton onClick={onClose} sx={{ position: 'absolute', right: 0 }}>
                        <Close sx={{ color: theme.palette.getContrastText(theme.palette.primary.main) }} />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ p: 0, backgroundColor: '#000' }}>
                {selected ? (
                    <Stack direction="row" spacing={1} p={1} justifyContent="center">
                        <IconButton
                            size="large"
                            color="primary"
                            sx={iconBtnSx}
                            onClick={() => handleDownload(selected.url, selected.filename)}
                            aria-label={`Download ${selected.filename}`}
                        >
                            <DownloadIcon />
                        </IconButton>

                        {!disableDelete && (
                            <IconButton
                                size="large"
                                color="error"
                                sx={iconBtnSx}
                                aria-label={`Delete ${selected?.filename ?? 'File'}`}
                                onClick={() => confirmDeleteImage(selected.id ?? 0)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </Stack>
                ) : null}

                <SliderCarousel images={attachments.map(({ url }) => url)} selectedItem={currImg} setSelectedItem={setCurrImg} />
            </DialogContent>
        </Dialog>
    );
};

export default AttachmentsGallery;
