import { FC, useCallback, useState } from 'react';
import { Button } from '@mui/material';
import CBModal from '../../../ui-component/cb-modal/CBModal';
import WebhookSettingsForm from './WebhookSettingsForm';
import useWebhookDefaults from './use-webhook-defaults';
import companyAPI from '../../../services/CompanyService';
import { Theme, useMediaQuery } from '@material-ui/core';
import { useAppDispatch } from '../../../hooks/redux';
import { startSubmitting, stopSubmitting } from '../../../store/slices/SubmittingSlice';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import { IWebhookSettingsInput } from '../types';

const NewWebhook: FC<{ companyId: number }> = ({ companyId }) => {
    const dispatch = useAppDispatch();
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);
    const { defaultValue, triggerData } = useWebhookDefaults(companyId);

    const [save, { isLoading: isSaving }] = companyAPI.useCreateCompanyWebhookMutation();
    const { showSnackbar } = useShowSnackbar();

    const handleCreate = useCallback(
        (data: IWebhookSettingsInput) => {
            if (!isSaving) {
                dispatch(startSubmitting());
                save({ companyId, data })
                    .unwrap()
                    .then((res) => {
                        showSnackbar({
                            alertSeverity: SnackBarTypes.Success,
                            message: 'Web Hook Created'
                        });
                        setOpen(false);
                    })
                    .catch((err) => {
                        showSnackbar({
                            alertSeverity: SnackBarTypes.Error,
                            message: err.data ?? err.message ?? JSON.stringify(err)
                        });
                    })
                    .finally(() => {
                        dispatch(stopSubmitting());
                    });
            }
        },
        [companyId, dispatch, isSaving, save, showSnackbar]
    );

    if (!triggerData) return null;

    return (
        <>
            <Button variant="contained" color="primary" disableElevation onClick={() => setOpen(true)}>
                Add Hook
            </Button>
            <CBModal
                open={open}
                onClose={() => setOpen(false)}
                okButtonFormId="webhook-form"
                title="Create Web Hook"
                okButtonText="Create"
                maxWidth="sm"
                fullWidth
                fullScreen={matchSm}
                okButtonDisabled={isSaving}
            >
                <WebhookSettingsForm defaults={defaultValue} onSave={handleCreate} triggersData={triggerData} />
            </CBModal>
        </>
    );
};

export default NewWebhook;
