import SectionHeading from '../SectionHeading';
import appointmentAPI from '../../../../../../services/AppointmentService';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useAppSelector } from '../../../../../../hooks/redux';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import PriceChangeIcon from '@mui/icons-material/PriceChangeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import PaidIcon from '@mui/icons-material/PaidOutlined';
import { styled } from '@mui/material/styles';
import moment from 'moment/moment';
import { useCallback } from 'react';
import { AppointmentHistoryEvent, IAppointmentHistory } from '../../../../../../models/IAppointment';

const StyledEventName = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    color: theme.palette.grey[600],
    fontSize: '16px',

    '& .MuiSvgIcon-root': {
        fontSize: '20px',
        color: theme.palette.grey[500]
    }
}));

const StyledDate = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[500],
    marginLeft: '25px',

    '& span': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        display: 'inline-block'
    }
}));

const Activity = () => {
    const { selectedEvent } = useAppSelector((state) => state.calendar);
    const { data, isLoading } = appointmentAPI.useGetAppointmentHistoryQuery(selectedEvent?.id ?? skipToken);

    const eventContent = {
        end_at: { icon: <AccessTimeIcon />, text: 'End time changed' },
        start_at: { icon: <AccessTimeIcon />, text: 'Start time changed' },
        service_id: { icon: <SettingsSuggestIcon />, text: 'Service changed' },
        price: { icon: <PriceChangeIcon />, text: 'Price changed' },
        employee_id: { icon: <PeopleAltOutlinedIcon />, text: 'Provider changed' },
        customer_id: { icon: <PeopleAltOutlinedIcon />, text: 'Customer changed' },
        note: { icon: <StickyNote2OutlinedIcon />, text: 'Note changed' },
        private_note: { icon: <StickyNote2OutlinedIcon />, text: 'Private note changed' },
        payments: { icon: <PaidIcon />, text: 'Payment was changed' }
    };

    const convertMessageData = useCallback((item: IAppointmentHistory) => {
        const icon = item.event_source === 'widget' ? <OpenInNewIcon /> : <EventIcon />;
        let text: string | null = null;
        switch (item.event) {
            case AppointmentHistoryEvent.Created: {
                text = 'Appointment created';
                break;
            }

            case AppointmentHistoryEvent.ServiceCreated: {
                text = 'Appointment service created';
                break;
            }

            case AppointmentHistoryEvent.ServiceUpdated: {
                text = 'Appointment service changed';
                break;
            }

            case AppointmentHistoryEvent.ServiceDeleted: {
                text = 'Appointment service deleted';
                break;
            }

            case AppointmentHistoryEvent.ProductCreated: {
                text = 'Appointment product created';
                break;
            }

            case AppointmentHistoryEvent.ProductUpdated: {
                text = 'Appointment product changed';
                break;
            }

            case AppointmentHistoryEvent.ProductDeleted: {
                text = 'Appointment product deleted';
                break;
            }
        }

        return text ? (
            <StyledEventName>
                {icon}
                {text}
            </StyledEventName>
        ) : null;
    }, []);

    return (
        <>
            <SectionHeading mb={2} px={2}>
                Appointment Activity
            </SectionHeading>
            <Divider />
            <Box p={2}>
                {isLoading ? (
                    <Box display="flex" alignItems="center" gap={1}>
                        <CircularProgress size={20} />
                        Loading...
                    </Box>
                ) : (
                    data?.map((item, index) => (
                        <Box key={`event-${index}`} mb={2}>
                            {convertMessageData(item)
                                ? convertMessageData(item)
                                : Object.keys(item.modified_values).map((key, i) => {
                                      const content = eventContent[key as keyof typeof eventContent];
                                      return (
                                          <StyledEventName key={`activity-${i}`}>
                                              {content ? (
                                                  <>
                                                      {content.icon}
                                                      {content.text}
                                                  </>
                                              ) : (
                                                  <>
                                                      <SettingsSuggestIcon />
                                                      <span style={{ textTransform: 'capitalize' }}>{key.replace('_', ' ')}</span> changed
                                                  </>
                                              )}
                                          </StyledEventName>
                                      );
                                  })}
                            <StyledDate>
                                {moment(item.created_at).format('MM/DD/YY h:mm a (z)')}{' '}
                                <span>{item.event_source === 'widget' ? 'via Widget' : `by ${item?.user?.title}`}</span>
                            </StyledDate>
                        </Box>
                    ))
                )}
            </Box>
        </>
    );
};

export default Activity;
