import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosServices } from 'utils/axios';

// material-ui
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid } from '@material-ui/core';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';

// project imports
import employeeAPI from 'services/EmployeeService';
import { IEmployee, IEmployeePayload } from 'models/IEmployee';
import { colors, initSchedule } from '../../store/constant';
import EmployeeForm from './EmployeeForm';
import ErrorCatcher from '../../ui-component/error-catcher';

const EMPLOYEE_DEFAULTS = {
    role: '',
    profession_title: '',
    locations: [],
    services: [],
    self_book: true,
    background_color: colors.blue.value.replace('#', ''),
    text_color: colors.white.value.replace('#', ''),
    schedule: initSchedule,
    is_shifts_enabled: false,
    shifts: [],
    settings: {
        widget: {
            use_location_schedule: true
        },
        integrations: {
            use_provider_payment_details: false
        }
    }
};

const EmployeeCreate: React.FC = () => {
    const navigate = useNavigate();
    const { email, id, exist } = useParams();
    const [loading, setLoading] = useState(false);
    const [initValue, setInitValue] = useState<IEmployee | null>();
    const [createEmployee] = employeeAPI.useCreateEmployeeMutation();

    const getInitValue = useCallback(async () => {
        if (Boolean(exist) && Boolean(id)) {
            setLoading(true);
            try {
                const res = await axiosServices.post('/employees/invite', { email });
                const init = ({
                    user: res.data.user,
                    ...EMPLOYEE_DEFAULTS
                } as unknown) as IEmployee;
                setInitValue(init);
                return;
            } catch (error) {
                return;
            } finally {
                setLoading(false);
            }
        }

        const init = ({
            user: {
                firstname: '',
                lastname: '',
                email,
                phone: ''
            },
            ...EMPLOYEE_DEFAULTS
        } as unknown) as IEmployee;
        setInitValue(init);
    }, [email, exist, id]);

    const handleCreate = (employee: IEmployeePayload) => createEmployee(employee);

    useEffect(() => {
        if (!initValue && !loading) {
            getInitValue();
        }
    }, [getInitValue, initValue, loading]);

    return (
        <ErrorCatcher wrappedComponentName="EmployeeCreate">
            <Grid>
                <MainCard
                    title="New Staff"
                    secondary={
                        <Button size="small" disableElevation onClick={() => navigate('/employee', { replace: true })}>
                            <ChevronLeftOutlinedIcon />
                            Go back
                        </Button>
                    }
                    contentSX={{ p: { xs: 1.5, sm: 3 } }}
                >
                    {initValue && <EmployeeForm employee={initValue} save={handleCreate} isInvite={!!id && !!exist} />}
                </MainCard>
            </Grid>
        </ErrorCatcher>
    );
};

export default EmployeeCreate;
