import { FormControlLabel, Switch } from '@mui/material';

interface NotificationsSwitchProps {
    value: boolean;
    onChange: (newValue: boolean) => void;
    disabled?: boolean;
}

const NotificationsSwitch = ({ value, onChange, disabled }: NotificationsSwitchProps) => (
    <FormControlLabel
        control={<Switch checked={value} onChange={(e, checked) => onChange(checked)} disabled={disabled} />}
        label="Send Notifications"
        labelPlacement="start"
        sx={{ ml: 0 }}
    />
);

export default NotificationsSwitch;
