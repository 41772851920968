import { FC, useCallback, useMemo } from 'react';
import { Autocomplete, Box, Button, FormHelperText, Grid, Stack, TextField } from '@mui/material';
import useServiceOptions from '../../../../hooks/options/useServiceOptions';
import { IService, ServiceLocationType } from '../../../../models/IService';
import NumberFormat, { SourceInfo } from 'react-number-format';
import SectionHeading from '../../../../views/calendar/appointment-card/appointment-details/elements/SectionHeading';
import RemoveIcon from '@mui/icons-material/Remove';
import { FormikErrors, FormikTouched } from 'formik';
import { getDefaultServicePrice, getMaterialsBasedServicePrice } from '../../../../utils/services';
import { IEmployee } from '../../../../models/IEmployee';
import FocusTextField from '../../../FocusTextField';

export type AppointmentServiceRowType = {
    service?: IService;
    price?: number | null;
    prepay?: number | null;
    materials_amount: number | null;
};

interface IAppointmentServicesSubformProps {
    value: Array<AppointmentServiceRowType>;
    setValue: (field: string, value: any) => void;
    setTouched: (field: string, touched?: boolean, shouldValidate?: boolean) => void;
    valuePrefix: string;
    useMultiservices: boolean;
    errors?: FormikErrors<AppointmentServiceRowType>[] | string | string[];
    touched?: FormikTouched<AppointmentServiceRowType[][number]>[];
    disabled?: boolean;
    employeeServices?: IEmployee['services'];
    serviceSelectCb?: (service: IService) => void;
}

const AppointmentServicesSubform: FC<IAppointmentServicesSubformProps> = ({
    value,
    setValue,
    setTouched,
    valuePrefix,
    useMultiservices,
    errors,
    touched,
    disabled,
    employeeServices,
    serviceSelectCb
}) => {
    const { services, isLoading } = useServiceOptions();

    const handleChangeService = useCallback(
        (v: IService | null, index: number) => {
            if (v) {
                const isMaterialBasedService = !!v?.use_materials;
                const price = getDefaultServicePrice(v, employeeServices || []);
                setValue(`${valuePrefix}.${index}.service`, v);
                setValue(`${valuePrefix}.${index}.price`, price ? parseFloat(String(price)) : 0);
                setValue(`${valuePrefix}.${index}.prepay`, v.prepay ? parseFloat(String(v.prepay)) : null);
                setValue(`${valuePrefix}.${index}.materials_amount`, isMaterialBasedService ? 0 : undefined);
                setTouched(`${valuePrefix}.${index}.materials_amount`, false);
                serviceSelectCb && serviceSelectCb(v);
            }
        },
        [employeeServices, serviceSelectCb, setTouched, setValue, valuePrefix]
    );

    const handlePriceChange = useCallback(
        (index: number, price: number | undefined | null, isChangedManually: boolean) => {
            setValue(`${valuePrefix}.${index}.price`, price);
            if (isChangedManually) {
                setValue(`${valuePrefix}.${index}.materials_amount`, 0);
            }
        },
        [setValue, valuePrefix]
    );

    const handleChangeAmount = useCallback(
        (index: number, amount: number | undefined, service: IService | null, isManual?: boolean) => {
            if (service && service.use_materials && service.materials && isManual) {
                const materialsPrice = getMaterialsBasedServicePrice(service, employeeServices || [], amount);
                handlePriceChange(index, materialsPrice, false);
            }
            setValue(`${valuePrefix}.${index}.materials_amount`, amount);
        },
        [employeeServices, handlePriceChange, setValue, valuePrefix]
    );

    const selectedServiceIds = useMemo<number[]>(() => {
        const result: number[] = [];
        value.forEach((service) => {
            if (service.service?.id) {
                result.push(service.service.id);
            }
        });

        return result;
    }, [value]);

    const getDifferentLocTypeServiceIds = useCallback(() => {
        const firsServiceLocType = value[0].service?.location_type;
        return firsServiceLocType ? services.filter((service) => service.location_type !== firsServiceLocType).map(({ id }) => id) : [];
    }, [services, value]);

    const getAvailableOptions = useCallback(
        (currentValue?: IService) => {
            const selectedOpts = selectedServiceIds.filter((id) => id !== currentValue?.id);
            // If more than one service selected, exclude services with different location type from the list
            const excludedOpts = value.length > 1 ? [...selectedOpts, ...getDifferentLocTypeServiceIds()] : selectedOpts;

            return services.filter((service) => !excludedOpts.includes(service.id));
        },
        [getDifferentLocTypeServiceIds, selectedServiceIds, services, value.length]
    );

    const onAddService = useCallback(() => {
        const defaultService = { service: null, price: null, prepay: null };
        setValue(valuePrefix, [...value, defaultService]);
    }, [setValue, value, valuePrefix]);

    const onRemoveService = (index: number) => {
        const newVal = [...value];
        newVal.splice(index, 1);
        setTouched(`${valuePrefix}.${index}.service`, false);
        setTouched(`${valuePrefix}.${index}.prepay`, false);
        setTouched(`${valuePrefix}.${index}.price`, false);
        setTouched(`${valuePrefix}.${index}.materials_amount`, false);
        setValue(valuePrefix, newVal);
    };

    const getFieldError = useCallback(
        (index: number, field: 'service' | 'price' | 'prepay' | 'materials_amount') => {
            const indexError = errors?.[index];
            const touchedEntry = touched?.[index];
            if (!indexError || typeof indexError === 'string') {
                return null;
            }

            if (!!indexError && typeof indexError !== 'string') {
                return touchedEntry?.[field] ? indexError[field] : null;
            }

            return null;
        },
        [errors, touched]
    );

    const canAddServices = useMemo(() => {
        if (value.length && !useMultiservices) {
            return false;
        }
        const hasAvailableServices = !!getAvailableOptions().length;
        const virtualServiceSelected = value.some((v) => v.service?.location_type === ServiceLocationType.Virtual);

        return hasAvailableServices && !virtualServiceSelected;
    }, [getAvailableOptions, useMultiservices, value]);

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <SectionHeading mt={1}>Services</SectionHeading>

            {value.map((row, index) => (
                <Stack spacing={1} key={`${row.service?.id}_${index}`}>
                    <Stack direction="row" spacing={2}>
                        <Grid container spacing={2} sx={{ flexGrow: 1, flexShrink: 1 }}>
                            <Grid item xs={6}>
                                <Autocomplete
                                    disableClearable
                                    id={`${valuePrefix}.${index}.service`}
                                    options={getAvailableOptions(row.service)}
                                    loading={isLoading}
                                    value={row.service}
                                    getOptionLabel={(opt) => opt.name}
                                    isOptionEqualToValue={(opt, v) => opt.id === v.id}
                                    onChange={(_e, v) => handleChangeService(v, index)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Service" error={Boolean(getFieldError(index, 'service'))} />
                                    )}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={row.service?.use_materials && row.service.materials ? 3 : 6}>
                                <NumberFormat
                                    fullWidth
                                    customInput={FocusTextField}
                                    label="Price"
                                    id={`${valuePrefix}.${index}.price`}
                                    name={`${valuePrefix}.${index}.price`}
                                    prefix="$"
                                    decimalScale={2}
                                    allowNegative={false}
                                    value={row.price}
                                    onBlur={() => setTouched(`${valuePrefix}.${index}.price`, true)}
                                    onValueChange={(v, src: SourceInfo) => {
                                        handlePriceChange(index, v.floatValue, !!src.event && src.source !== 'prop');
                                    }}
                                    error={Boolean(getFieldError(index, 'price'))}
                                    disabled={disabled}
                                />
                            </Grid>

                            {row.service?.use_materials && row.service.materials && (
                                <Grid item xs={3}>
                                    <NumberFormat
                                        fullWidth
                                        customInput={TextField}
                                        label={`${row.service.materials.name} (${row.service.materials.units})`}
                                        id={`${valuePrefix}.${index}.materials_amount`}
                                        name={`${valuePrefix}.${index}.materials_amount`}
                                        decimalScale={2}
                                        allowNegative={false}
                                        value={row.materials_amount}
                                        onBlur={() => setTouched(`${valuePrefix}.${index}.materials_amount`, true)}
                                        onValueChange={(v, src: SourceInfo) =>
                                            handleChangeAmount(
                                                index,
                                                v.floatValue,
                                                row.service || null,
                                                !!src.event && src.source !== 'prop'
                                            )
                                        }
                                        error={Boolean(getFieldError(index, 'materials_amount'))}
                                        disabled={disabled}
                                    />
                                </Grid>
                            )}

                            {/* {row.service && row.service.payment_type === PaymentType.Prepaid && ( */}
                            {/*    <Grid item xs={2}> */}
                            {/*        <NumberFormat */}
                            {/*            customInput={TextField} */}
                            {/*            id={`${valuePrefix}.${index}.prepay`} */}
                            {/*            name={`${valuePrefix}.${index}.prepay`} */}
                            {/*            label="Deposit" */}
                            {/*            prefix="$" */}
                            {/*            decimalScale={2} */}
                            {/*            allowNegative={false} */}
                            {/*            value={row.prepay} */}
                            {/*            onBlur={() => setTouched(`${valuePrefix}.${index}.prepay`, true)} */}
                            {/*            onValueChange={(v) => handleFieldChange(`${valuePrefix}.${index}.prepay`, v.floatValue)} */}
                            {/*            error={Boolean(getFieldError(index, 'prepay'))} */}
                            {/*            disabled={disabled} */}
                            {/*        /> */}
                            {/*    </Grid> */}
                            {/* )} */}
                        </Grid>

                        {/* User can't remove last service */}
                        {value.length > 1 && (
                            <Button
                                variant="outlined"
                                sx={{
                                    width: '44px',
                                    height: '44px',
                                    p: 0,
                                    minWidth: 0,
                                    flexGrow: 0,
                                    flexShrink: 0
                                }}
                                onClick={() => onRemoveService(index)}
                                disabled={disabled}
                            >
                                <RemoveIcon />
                            </Button>
                        )}
                    </Stack>

                    <Box>
                        {getFieldError(index, 'service') && <FormHelperText error>{getFieldError(index, 'service')}</FormHelperText>}
                        {getFieldError(index, 'price') && <FormHelperText error>{getFieldError(index, 'price')}</FormHelperText>}
                        {getFieldError(index, 'prepay') && <FormHelperText error>{getFieldError(index, 'prepay')}</FormHelperText>}
                        {getFieldError(index, 'materials_amount') && (
                            <FormHelperText error>{getFieldError(index, 'materials_amount')}</FormHelperText>
                        )}
                    </Box>
                </Stack>
            ))}

            {canAddServices && (
                <Box>
                    <Button onClick={onAddService} disabled={disabled}>
                        + Add Service
                    </Button>
                </Box>
            )}
        </Stack>
    );
};

export default AppointmentServicesSubform;
