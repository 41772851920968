import { FC, PropsWithChildren } from 'react';
import { ButtonBase, ButtonBaseProps, useMediaQuery } from '@mui/material';
import { useTheme } from '@material-ui/core/styles';

const CommonHeaderBtn: FC<PropsWithChildren<ButtonBaseProps>> = ({ sx, children, ...rest }) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const size = matchDownMd ? '36px' : '44px';
    const iconSize = matchDownMd ? '1.3rem' : '1.5rem';

    const baseSx = {
        ...sx,
        background: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.secondary.light,
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
        transition: 'all .2s ease-in-out',
        borderRadius: theme.spacing(1.5),
        alignSelf: 'stretch',
        width: size,
        height: size,
        flexShrink: 0,

        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
        },

        '& svg': {
            width: iconSize,
            height: iconSize
        }
    };

    return (
        <ButtonBase sx={baseSx} {...rest}>
            {children}
        </ButtonBase>
    );
};

export default CommonHeaderBtn;
