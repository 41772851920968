import { FC } from 'react';
import { Box, FormHelperText, InputBase, MenuItem, TextField } from '@mui/material';
import { isNumber } from 'lodash';

interface IDepositRequestReminderProps {
    reminderValue: number;
    reminderUnit: string;
    onChangeValue: (v: string | number) => void;
    onChangeAmount: (v: string) => void;
    errors?: string[];
    prefix?: string;
}

const DepositRequestReminder: FC<IDepositRequestReminderProps> = ({
    reminderValue,
    reminderUnit,
    onChangeValue,
    onChangeAmount,
    errors,
    prefix
}) => {
    const handleValueChange = (v: string) => {
        const parse = parseInt(v, 10);
        const isValid = isNumber(parse) && !isNaN(parse);
        onChangeValue(isValid ? parse : '');
    };

    return (
        <Box>
            <Box
                sx={{
                    position: 'relative',
                    '&:hover .MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#616161'
                    },
                    '&:focus-within': {
                        '.MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1889b8'
                        },
                        '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '2px'
                        },
                        '.MuiInputLabel-root:not(.Mui-error)': {
                            color: '#1889b8'
                        }
                    }
                }}
            >
                <InputBase
                    id={prefix ? `${prefix}.reminder.value` : 'reminder.value'}
                    name={prefix ? `${prefix}.reminder.value` : 'reminder.value'}
                    value={reminderValue}
                    onChange={(e) => handleValueChange(e.target.value)}
                    aria-label="Reminder Value"
                    autoComplete="off"
                    error={!!errors?.length}
                    sx={{
                        position: 'absolute',
                        left: '1px',
                        top: 0,
                        bottom: 0,
                        zIndex: 1,
                        width: '65px',
                        paddingLeft: '14px'
                    }}
                />
                <TextField
                    id={prefix ? `${prefix}.reminder.unit` : 'reminder.unit'}
                    name={prefix ? `${prefix}.reminder.unit` : 'reminder.unit'}
                    label="Exp. Reminder"
                    value={reminderUnit}
                    onChange={(e) => onChangeAmount(e.target.value)}
                    fullWidth
                    select
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ startAdornment: <Box sx={{ width: '65px' }} /> }}
                    error={!!errors?.length}
                >
                    {['minutes', 'hours', 'days', 'months'].map((unit) => (
                        <MenuItem value={unit} key={unit} sx={{ textTransform: 'capitalize' }}>
                            {unit}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <FormHelperText error>{errors?.join(', ')}</FormHelperText>
        </Box>
    );
};

export default DepositRequestReminder;
